<template>
  <div v-if="isBy">
    <van-field
      :is-link="editable"
      :clickable="editable"
      :readonly="true"
      :required="editable"
      :value="innerLabel"
      name="projectLeader"
      :label="title"
      :placeholder="editable ? '请选择' : '--'"
      input-align="right"
      error-message-align="right"
      :rules="[
        {
          required: true,
          message: ' ',
          trigger: 'onChange'
        }
      ]"
      v-bind="$attrs"
      v-on="$listeners"
      @click="handleClick"
    />
    <select-person-by-tabs
      :title="title"
      :visible="popupVisible"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="false"
      :is-department-auth="false"
      :default-department="defaultDepartment"
      default-contractor=""
      work-type-id=""
      license-types=""
      contractor-license-types=""
      :recent-type="personType"
      :contractor-worker-type="personType"
      :choose-value="selectedItems"
      @close="handlePopupClose"
      @confirm="handlePopupConfirm"
    />
  </div>
</template>

<script>
export default {
  name: "ProjectLeader",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    phoneNumber: {
      type: String,
      default: ""
    },
    defaultDepartment: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: "项目负责人",
      personType: "35",
      innerLabel: "",
      innerValue: "",
      options: [],
      popupVisible: false,
      selectedItems: []
    };
  },
  created() {
    this.setData(this.value);
  },
  methods: {
    handleClick() {
      if (!this.editable) return;
      this.popupVisible = true;
    },
    handlePopupClose() {
      this.popupVisible = false;
    },
    handlePopupConfirm(ids, list) {
      // 企业人员取 workMobile，承包商取 mobile
      const phoneNumber = list[0]?.workMobile || list[0]?.mobile || "";
      const newList = list.map(item => {
        item.personType = this.personType;
        return item;
      });
      this.$emit("update:value", newList);
      this.$emit("update:phoneNumber", phoneNumber);
      this.popupVisible = false;
    },
    setData(newVal) {
      const newData = newVal || [];
      this.selectedItems = newData;
      this.innerLabel = newData[0]?.label || newData[0]?.name || "";
      this.innerValue = newData[0]?.value || newData[0]?.id || "";
    }
  },
  watch: {
    value(newVal) {
      this.setData(newVal);
    }
  }
};
</script>

<style lang="scss" scoped></style>
