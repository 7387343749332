var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isBy)?_c('div',[_c('van-field',_vm._g(_vm._b({attrs:{"is-link":_vm.editable,"clickable":_vm.editable,"readonly":true,"required":_vm.editable,"value":_vm.innerLabel,"name":"projectRiskLevel","label":_vm.title,"placeholder":_vm.editable ? '请选择' : '--',"input-align":"right","error-message-align":"right","rules":[
      {
        required: true,
        message: ' ',
        trigger: 'onChange'
      }
    ]},on:{"click":_vm.handleClick}},'van-field',_vm.$attrs,false),_vm.$listeners)),_c('SelectPopup',{attrs:{"popup-style":{
      height: '50vh'
    },"choose-value":_vm.innerValue,"show-filter":false,"data":_vm.options,"title":_vm.title,"visible":_vm.popupVisible},on:{"close":_vm.handlePopupClose,"confirm":_vm.handlePopupConfirm}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }