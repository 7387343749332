<template>
  <van-popup
    v-model="visible"
    class="work-evaluation"
    :close-on-click-overlay="false"
    position="bottom"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="header">
      <h3 class="title">作业评价</h3>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click="close"
      />
    </div>
    <hr />
    <section class="container">
      <div
        v-for="(item, index) in data.evaluateQuotaList"
        :key="index"
        class="item-box"
      >
        <van-row gutter="20">
          <van-col span="18" class="item-title">
            {{ `${index + 1}` }}. {{ item.quota }}
            <van-icon
              v-if="item.explanation"
              class="tip-icon"
              name="question"
              @click="showTip(item)"
            />
          </van-col>
          <van-col span="6" class="item-score"
            >评分：<span class="color-orange">{{
              item.score !== null ? item.score : "--"
            }}</span></van-col
          >
        </van-row>
        <van-row>
          <van-col span="24" class="border-bottom">
            <van-radio-group v-model="item.score">
              <van-radio
                v-for="(itemS, indexS) in item.scoreItemList"
                :key="indexS"
                :name="itemS.scoreValue"
                :disabled="!editable && itemS.scoreValue !== item.score"
                >{{ itemS.itemName }}</van-radio
              >
            </van-radio-group>
          </van-col>
        </van-row>
      </div>
      <hr />
      <div class="remark-title">备注：</div>
      <van-field
        v-if="editable"
        v-model="data.evaluateRemark"
        class="remark-box"
        rows="2"
        :autosize="{ maxHeight: 250 }"
        type="textarea"
        maxlength="200"
        placeholder="请输入备注内容，200字以内"
        show-word-limit
      />
      <div v-else class="remark-content">
        {{ data.evaluateRemark }}
      </div>
    </section>
    <div v-if="editable" class="footer">
      <van-button class="btn-default" @click="close">取消</van-button>
      <van-button class="btn-primary" @click="save">保存</van-button>
    </div>
    <van-dialog
      v-model="tipVisible"
      :show-confirm-button="false"
      :title="tipInfo.quota"
      close-on-click-overlay
    >
      <div class="tip-content">
        {{ tipInfo.explanation }}
      </div>
    </van-dialog>
  </van-popup>
</template>

<script>
import { saveTicketEvaluate } from "@/api/workticket";
export default {
  name: "WorkEvaluation",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    data: Object
  },
  data() {
    return {
      visible: false,
      editable: true,
      tipVisible: false,
      tipInfo: {}
    };
  },
  methods: {
    open(editable = true) {
      this.editable = editable;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    async save() {
      const { evaluateQuotaList, evaluateRemark } = this.data;
      const optionsCheck =
        evaluateQuotaList.length &&
        evaluateQuotaList.every(item => item.score !== null);
      if (!optionsCheck) {
        this.$toast.fail("内容未填写完整");
        return;
      }
      const params = {
        ...this.data,
        scoreItemList: evaluateQuotaList.map(item => {
          const quotaId = item.id;
          const scoreItemId = item.scoreItemList.find(
            scoreItem => scoreItem.scoreValue === item.score
          ).id;
          const score = item.score;
          return { quotaId, scoreItemId, score };
        }),
        evaluateRemark: evaluateRemark
      };
      this.data.evaluateResult = await saveTicketEvaluate(params);
      this.close();
    },
    showTip(item) {
      this.tipInfo = item;
      this.tipVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.work-evaluation {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  hr {
    margin: 0;
  }
  .color-orange {
    color: #f3961c;
  }
  .border-bottom {
    border-bottom: 1px solid #e1e3e8;
  }
  .header {
    position: relative;
    text-align: center;
    color: #2e2e4d;
    padding: 30px 0 20px;
    .close-icon {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .container {
    height: calc(100vh - 100px - 80px);
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    .item-box {
      margin-top: 20px;
    }
    .van-row {
      padding: 0 16px;
    }
    .item-title {
      color: #2e2e4d;
      font-weight: bold;
    }
    .item-score {
      font-size: 12px;
      color: #8c8f97;
      text-align: right;
    }
    .van-radio-group {
      width: 100%;
      padding: 18px 14px 8px;
      .van-radio {
        margin-bottom: 10px;
      }
    }
    .remark-title {
      margin: 16px 16px 0;
    }
    .remark-content {
      color: #8c8f97;
      margin: 16px 16px 8px;
    }
    .tip-icon {
      color: #c0c4cc;
    }
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 16px;
    display: flex;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    padding: 0px 16px;
    .van-button {
      flex-grow: 1;
      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
  .tip-content {
    padding: 20px;
    font-size: 14px;
    color: #242f3b;
  }
  .remark-box {
    ::v-deep {
      .van-cell__value {
        border: 1px solid #e1e3e8;
        padding: 8px 10px 0;
        .van-field__word-limit {
          padding: 0 0 3px;
        }
      }
    }
  }
}
</style>
