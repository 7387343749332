import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";
const api = apiPath.basics;

/**
 *
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getdeptInfoByFacilityId(params) {
  return axios.get(api + "/info/facilities/dept-code", { params });
}
