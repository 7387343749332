<template>
  <div v-if="isBy">
    <van-field
      :is-link="editable"
      :clickable="editable"
      :readonly="true"
      :required="editable"
      :value="innerLabel"
      name="projectCategory"
      :label="title"
      :placeholder="editable ? '请选择' : '--'"
      input-align="right"
      error-message-align="right"
      :rules="[
        {
          required: true,
          message: ' ',
          trigger: 'onChange'
        }
      ]"
      v-bind="$attrs"
      v-on="$listeners"
      @click="handleClick"
    />
    <SelectPopup
      :popup-style="{
        height: '50vh'
      }"
      :choose-value="innerValue"
      :show-filter="false"
      :data="options"
      :title="title"
      :visible="popupVisible"
      @close="handlePopupClose"
      @confirm="handlePopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { getProjectCategory } from "@/api/workticket";

export default {
  name: "ProjectCategory",
  components: { SelectPopup },
  props: {
    value: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: "项目类别",
      innerLabel: "",
      innerValue: "",
      options: [],
      popupVisible: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.options = await getProjectCategory();
      this.setValue(this.value);
    },
    handleClick() {
      if (!this.editable) return;
      this.popupVisible = true;
    },
    handlePopupClose() {
      this.popupVisible = false;
    },
    handlePopupConfirm({ value }) {
      this.popupVisible = false;
      this.$emit("update:value", value);
    },
    setValue(val) {
      const find = this.options.find(item => item.value === val);
      this.innerLabel = find?.label ?? "";
      this.innerValue = find?.value ?? "";
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("update:value", newVal);
    },
    value(newVal) {
      this.setValue(newVal);
    }
  }
};
</script>

<style lang="scss" scoped></style>
