<template>
  <div class="aaa">
    <van-nav-bar
      :title="navBarTitleSwitch"
      fixed
      left-arrow
      @click-left="navBarBack()"
    >
      <template #right>
        <i
          class="iconfont iconfont-liucheng icon-process"
          @click="clickProcess"
        ></i>
      </template>
    </van-nav-bar>
    <div class="form-box">
      <van-form ref="form" class="form">
        <div class="group-title-box">
          <span class="title">基本信息</span>
          <van-button
            v-if="editable"
            class="choose-temp-btn"
            round
            size="small"
            type="info"
            native-type="button"
            @click="tempDLOpen()"
            >选择模板</van-button
          >
        </div>
        <!-- <van-field
          v-if="!editable"
          v-model="formBase.jsaCode"
          name="jsaCode"
          label="编号"
          placeholder=""
          input-align="right"
          error-message-align="right"
          readonly
        /> -->
        <van-field
          v-model="formBase.jsaName"
          name="jsaName"
          :label="projectTextConfig.TEXT_JSA_NAME"
          :placeholder="`请输入${projectTextConfig.TEXT_JSA_NAME}`"
          maxlength="100"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :clickable="editable"
          :readonly="!editable"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="editable && (nameVisible = true)"
        />
        <van-field
          v-model="formBase.jsaDescribe"
          :clickable="editable"
          :readonly="!editable"
          name="jsaDescribe"
          label="作业任务简述"
          :placeholder="editable ? '请输入内容' : ''"
          maxlength="500"
          clearable
          input-align="right"
          error-message-align="right"
        />
        <van-field
          v-model="formBase.departmentNames"
          name="departmentName"
          label="所属部门"
          :is-link="editable"
          :required="editable"
          clearable
          placeholder="请选择"
          readonly
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="editable && (departmentVisible = true)"
        />
        <van-field
          :is-link="editable"
          :clickable="editable"
          :readonly="!editable"
          :value="formBase.facilityName"
          name="facilityName"
          :label="projectTextConfig.TEXT_FACILITY_NAME"
          placeholder="请选择"
          :required="editable"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="editable && (facilityVisible = true)"
        />
        <van-field
          :value="formBase.riskAreaName"
          name="riskAreaId"
          :is-link="editable"
          readonly
          :required="false"
          label="电子围栏"
          placeholder="请选择"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: false,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="editable && (fencePopupVisible = true)"
        />
        <van-field
          v-model="formBase.workLocation"
          :clickable="editable"
          :readonly="!editable"
          name="workLocation"
          :label="projectTextConfig.TEXT_WORK_LOCATION"
          :placeholder="editable ? '请输入内容' : ''"
          maxlength="100"
          clearable
          input-align="right"
          error-message-align="right"
          :required="editable"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            },
            {
              pattern: /^[\da-zA-Z\u4e00-\u9fa5]*$/,
              message: '必须为中英文或数字',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          readonly
          :is-link="editable"
          :clickable="editable"
          :value="formBase.planStart"
          name="planStart"
          label="计划开始时间"
          placeholder="请选择"
          :required="editable"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: dynamicRule,
              message: ' ',
              trigger: 'onChange'
            },
            {
              validator: planStartValidator,
              message: '开始时间不能晚于结束时间',
              trigger: 'onChange'
            }
          ]"
          @click="planTimeClick('planStart')"
        />
        <van-field
          readonly
          :is-link="editable"
          :clickable="editable"
          :value="formBase.planEnd"
          name="planEnd"
          label="计划结束时间"
          placeholder="请选择"
          :required="editable"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: dynamicRule,
              message: ' ',
              trigger: 'onChange'
            },
            {
              validator: planEndValidator,
              message: '结束时间不能早于开始时间',
              trigger: 'onChange'
            }
          ]"
          @click="planTimeClick('planEnd')"
        />
        <MaintenanceHours
          v-model="formBase.maintenanceHours"
          :start-time="formBase.planStart"
          :end-time="formBase.planEnd"
          :editable="editable"
        />
        <!-- :value="workTypeListValueSwitch" -->
        <!-- :error="!workTypeListValueSwitch" -->
        <van-field
          readonly
          :is-link="editable"
          :clickable="editable"
          :value="workTypeListValueSwitch"
          name="workTypeList"
          label="涉及作业类别"
          placeholder="请选择"
          :required="editable"
          input-align="right"
          error-message-align="right"
          :error="!workTypeListValueSwitch"
          :rules="[
            {
              required: dynamicRule,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="workTypeClick()"
        >
          <template #input>
            <span :style="{ color: workTypeColorSwitch }">{{
              editable ? "请选择" : ""
            }}</span>
          </template>
        </van-field>
        <div class="work-type-list-box">
          <div
            v-for="(item, idx) of formBase.workTypeList"
            :key="idx"
            class="item-box"
          >
            <div class="left-box">
              <p class="type-name">{{ item.name }}</p>
              <p>
                {{ projectTextConfig.TEXT_WORK_DEPT }}：{{
                  item.contractorStatus == 1
                    ? item.deptShortName || item.deptName
                    : item.deptName
                }}
              </p>
              <p>所需资质：{{ (item.licenseTypeName || []).toString() }}</p>
              <p v-if="isBy">
                人员：{{
                  (item.workTypePerson || [])
                    .map(item => item.personName)
                    .toString()
                }}
              </p>
            </div>
            <div>
              <span
                v-if="editable"
                class="close-box"
                @click="workTypeListDel(idx)"
                >×</span
              >
            </div>
          </div>
        </div>
        <ProjectCategory
          :value.sync="formBase.projectType"
          :editable="editable"
        />
        <ProjectRiskLevel
          :value.sync="formBase.riskLevel"
          :editable="editable"
        />
        <ProjectLeader
          :value.sync="formBase.jsaProjectLeaderList"
          :phone-number.sync="formBase.jsaProjectLeaderPhone"
          :default-department="formBase.department"
          :editable="editable"
        />
        <van-field
          v-if="isBy"
          :is-link="false"
          :clickable="false"
          :readonly="true"
          :required="false"
          :value="formBase.jsaProjectLeaderPhone"
          name="jsaProjectLeaderPhone"
          label="联系方式"
          placeholder="根据选择的项目负责人自动带入"
          input-align="right"
          error-message-align="right"
        />
        <SafetyLeader
          :value.sync="formBase.jsaSafetyLeaderList"
          :editable="editable"
          :default-department="formBase.department"
        />
        <OverhaulLeader
          :value.sync="formBase.jsaOverhaulLeaderList"
          :editable="editable"
          :default-department="formBase.department"
        />
        <RadioEditField
          v-if="isBy"
          v-model="formBase.changeFlag"
          label="是否涉及变更"
          :editing="editable"
          v-bind="fieldProps"
          required
          :rules="[
            {
              required: true,
              message: '请选择',
              trigger: 'onChange'
            }
          ]"
        />
        <ProjectSource
          :value.sync="formBase.projectSource"
          :editable="editable"
        />
        <van-field
          v-if="isBy"
          v-model="formBase.remarks"
          label="备注"
          rows="2"
          :autosize="{ maxHeight: 250 }"
          type="textarea"
          maxlength="1000"
          placeholder="请输入内容"
          input-align="right"
          error-message-align="right"
          show-word-limit
        />
        <div v-if="isRH && rescuePlanVisible" class="sa-box rescue-plan-wrap">
          <div class="title-bar-1">
            <div class="title">
              <i class="iconfont iconfont-jiuyuanjihua"></i>救援计划
            </div>
            <div class="content"></div>
          </div>
          <div class="title-level-2">
            受限空间救援计划
          </div>
          <CheckboxEditField
            v-model="rescuePlanForm.planIds"
            :multiple="true"
            :editing="editable"
            :options="rescuePlanForm.plan"
            option-value-key="dictId"
            option-label-key="name"
            :rules="[{ required: false }]"
          >
            <template slot="tagContent" slot-scope="scope">
              <div class="pp-tag-content">
                <span>{{ scope.item.name }}</span>
                <van-icon
                  v-if="scope.item.delete"
                  class="pp-option-close"
                  name="clear"
                  @click.stop="planDelete(scope.item.dictId)"
                />
              </div>
            </template>
            <Tag v-if="editable" type="white" @click.native="planAdd">
              <van-icon name="plus" />
            </Tag>
          </CheckboxEditField>
          <div class="separate"></div>
          <div class="title-level-2" style="padding-bottom: 0">
            救援方法
          </div>
          <div class="title-level-3">受限空间外部</div>
          <div
            v-for="(row, key) in rescuePlanForm.measureOuter"
            :key="row.id"
            class="list-box"
          >
            <div class="item-box">
              <div v-if="editable" class="left">
                <van-checkbox
                  v-model="row.checked"
                  icon-size="16px"
                  shape="square"
                ></van-checkbox>
              </div>
              <div class="middle">{{ key + 1 }}. {{ row.name }}</div>
            </div>
          </div>
          <div class="title-level-3">受限空间内部</div>
          <div
            v-for="(row, key) in rescuePlanForm.measureInner"
            :key="row.id"
            class="list-box"
          >
            <div class="item-box">
              <div v-if="editable" class="left">
                <van-checkbox
                  v-model="row.checked"
                  icon-size="16px"
                  shape="square"
                ></van-checkbox>
              </div>
              <div class="middle">{{ key + 1 }}. {{ row.name }}</div>
            </div>
          </div>
          <div class="title-level-3">补充救援措施</div>
          <div
            v-for="(row, key) in rescuePlanForm.measureOther"
            :key="key"
            class="list-box"
          >
            <div class="item-box">
              <div v-if="editable" class="left">
                <span
                  class="delete-btn iconfont iconfont-cancle"
                  @click="delMeasureOther(key)"
                ></span>
              </div>
              <div class="middle">
                <p class="title">{{ key + 1 }}. {{ row.name }}</p>
              </div>
            </div>
          </div>
          <div class="group-title-box">
            <p v-if="editable" class="sa-btn-box" @click="addMeasureOther">
              <span
                class="iconfont iconfont-tianjiagongzuobuzhou"
                style="color: #1676ff"
              />
              <span>添加补充措施</span>
            </p>
          </div>
          <div class="separate"></div>
          <div class="title-level-2">
            救援设备
          </div>
          <CheckboxEditField
            v-model="rescuePlanForm.deviceIds"
            :multiple="true"
            :editing="editable"
            :options="rescuePlanForm.device"
            option-value-key="dictId"
            option-label-key="name"
            :rules="[{ required: false }]"
          >
            <template slot="tagContent" slot-scope="scope">
              <div class="pp-tag-content">
                <span>{{ scope.item.name }}</span>
                <van-icon
                  v-if="scope.item.delete"
                  class="pp-option-close"
                  name="clear"
                  @click.stop="deviceDelete(scope.item.dictId)"
                />
              </div>
            </template>
            <Tag v-if="editable" type="white" @click.native="deviceAdd">
              <van-icon name="plus" />
            </Tag>
          </CheckboxEditField>
        </div>
        <div v-if="isRH && rescuePlanVisible" class="separate"></div>
        <van-field
          v-if="isRH && rescuePlanVisible"
          class="custom-input-person"
          :value="getPersonLabel(rescuePlanForm.territorialLeaderList)"
          name="territorialLeaderList"
          label="属地负责人"
          placeholder="请选择人员"
          :clickable="editable"
          :is-link="editable"
          readonly
          v-bind="fieldProps"
          required
          :rules="[
            {
              required: true,
              message: '请选择属地负责人',
              trigger: 'onChange'
            }
          ]"
          @click="editable && !id && (territorialLeaderVisible = true)"
        >
          <template #input>
            <div>
              <template v-if="id">
                <Tag
                  v-for="p in rescuePlanForm.territorialLeaderList"
                  :key="p.dictId"
                  :type="p.fileId ? 'green' : 'white'"
                  :tick="!!p.fileId"
                  @click.native="signatureClick(p, formBase.status < 3)"
                >
                  {{ p.name }}
                </Tag>
              </template>
              <template v-else>
                <span
                  v-for="p in rescuePlanForm.territorialLeaderList"
                  :key="p.dictId"
                  class="person-box"
                  >{{ p.name }}</span
                >
              </template>
            </div>
          </template>
        </van-field>
        <van-field
          v-if="type === 'detail' && !editable"
          readonly
          :value="formBase.analyst.name"
          name="analyst"
          label="更新人"
          placeholder="请选择"
          input-align="right"
        >
        </van-field>
        <van-field
          v-if="type === 'detail' && !editable"
          readonly
          :value="formBase.analysisDate"
          name="analyst"
          label="更新时间"
          placeholder="请选择"
          input-align="right"
        >
        </van-field>
        <div
          v-if="
            formBase.status === 1 ||
              formBase.status === 2 ||
              formBase.status === 3
          "
          class="separate"
        ></div>
        <div
          v-if="
            formBase.status === 1 ||
              formBase.status === 2 ||
              formBase.status === 3
          "
          class="group-title-box"
        >
          <div class="title">关联作业票</div>
        </div>
        <ul
          v-if="
            formBase.status === 1 ||
              formBase.status === 2 ||
              formBase.status === 3
          "
          class="work-link-box"
        >
          <li v-for="item of workJSAList.list" :key="item.id" class="item-box">
            <div class="left-box">
              <van-icon
                class="iconfont-lianjie"
                name="lianjie"
                class-prefix="iconfont"
                size="16"
              />
            </div>
            <div class="content">
              <span @click="workTicketRouterLink(item.id)">
                {{
                  `【${item.statusName}】${item.name}（${item.workType}）${item.code}`
                }}
              </span>
            </div>
          </li>
        </ul>
        <div class="separate"></div>
        <div class="group-title-box">
          <div class="title">
            安全分析
          </div>
        </div>
        <RadioEditField
          v-model="formBase.openAnalysis"
          :label="TEXT_OPEN_ANALYSIS"
          :editing="editable"
          v-bind="fieldProps"
          required
          :rules="[
            {
              required: true,
              message: '请选择',
              trigger: 'onChange'
            }
          ]"
        />
        <RadioEditField
          v-model="formBase.workPlan"
          label="需要安全施工方案"
          :editing="editable"
          v-bind="fieldProps"
          required
          :rules="[
            {
              required: true,
              message: '请选择',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-if="
            (editable && formBase.workPlan == 1) ||
              (!editable && formBase.planFile.length)
          "
          name="planFile"
          :readonly="!editable"
          v-bind="fieldProps"
          input-align="left"
          error-message-align="left"
          :rules="[
            {
              required: formBase.workPlan == 1,
              message: '请上传安全施工方案',
              trigger: 'onChange'
            }
          ]"
        >
          <template #input>
            <FileUploader
              v-model="formBase.planFile"
              :max-count="5"
              :unable-upload="!editable"
              :accept="FILE_ACCEPT_TYPES.FILE"
            />
          </template>
        </van-field>
        <RadioEditField
          v-model="formBase.rescuePlan"
          label="需要救援方案"
          :editing="editable"
          v-bind="fieldProps"
          required
          :rules="[
            {
              required: true,
              message: '请选择',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-if="
            (editable && formBase.rescuePlan == 1) ||
              (!editable && formBase.rescueFile.length)
          "
          :readonly="!editable"
          v-bind="fieldProps"
          name="rescueFile"
          input-align="left"
          error-message-align="left"
          :rules="[
            {
              required: formBase.rescuePlan == 1,
              message: '请上传救援方案',
              trigger: 'onChange'
            }
          ]"
        >
          <template #input>
            <FileUploader
              v-model="formBase.rescueFile"
              :max-count="5"
              :unable-upload="!editable"
              :accept="FILE_ACCEPT_TYPES.FILE"
            />
          </template>
        </van-field>
        <template
          v-if="formBase.workTypeList.find(i => i.specialJobType === '10')"
        >
          <RadioEditField
            v-model="formBase.construction"
            label="需要施工方案"
            :editing="editable"
            v-bind="fieldProps"
            required
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-if="
              (editable && formBase.construction == 1) ||
                (!editable && formBase.constructionFile.length)
            "
            :readonly="!editable"
            v-bind="fieldProps"
            name="constructionFile"
            input-align="left"
            error-message-align="left"
            :rules="[
              {
                required: formBase.construction == 1,
                message: '请上传施工方案',
                trigger: 'onChange'
              }
            ]"
          >
            <template #input>
              <FileUploader
                v-model="formBase.constructionFile"
                :max-count="5"
                :unable-upload="!editable"
                :accept="FILE_ACCEPT_TYPES.FILE"
              />
            </template>
          </van-field>
          <RadioEditField
            v-model="formBase.drawing"
            label="图纸"
            :editing="editable"
            v-bind="fieldProps"
            required
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-if="
              (editable && formBase.drawing == 1) ||
                (!editable && formBase.drawingFile.length)
            "
            :readonly="!editable"
            v-bind="fieldProps"
            name="drawingFile"
            input-align="left"
            error-message-align="left"
            :rules="[
              {
                required: formBase.drawing == 1,
                message: '请上传图纸',
                trigger: 'onChange'
              }
            ]"
          >
            <template #input>
              <FileUploader
                v-model="formBase.drawingFile"
                :max-count="5"
                :unable-upload="!editable"
                :accept="FILE_ACCEPT_TYPES.FILE"
              />
            </template>
          </van-field>
        </template>
        <template v-if="isRH">
          <RadioEditField
            v-model="formBase.drawing"
            label="需要图纸"
            :editing="editable"
            v-bind="fieldProps"
            required
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-if="
              (editable && formBase.drawing == 1) ||
                (!editable && formBase.drawingFile.length)
            "
            :readonly="!editable"
            v-bind="fieldProps"
            name="drawingFile"
            input-align="left"
            error-message-align="left"
            :rules="[
              {
                required: formBase.drawing == 1,
                message: '请上传图纸',
                trigger: 'onChange'
              }
            ]"
          >
            <template #input>
              <FileUploader
                v-model="formBase.drawingFile"
                :max-count="5"
                :unable-upload="!editable"
                :accept="FILE_ACCEPT_TYPES.FILE"
              />
            </template>
          </van-field>
          <RadioEditField
            v-model="formBase.other"
            label="需要其他附件"
            :editing="editable"
            v-bind="fieldProps"
            required
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-if="
              (editable && formBase.other == 1) ||
                (!editable && formBase.otherFile.length)
            "
            :readonly="!editable"
            v-bind="fieldProps"
            name="otherFile"
            input-align="left"
            error-message-align="left"
            :rules="[
              {
                required: formBase.other == 1,
                message: '请上传其他附件',
                trigger: 'onChange'
              }
            ]"
          >
            <template #input>
              <FileUploader
                v-model="formBase.otherFile"
                :max-count="5"
                :unable-upload="!editable"
                :accept="FILE_ACCEPT_TYPES.FILE"
              />
            </template>
          </van-field>
        </template>
        <van-cell
          v-if="formBase.uuserName && formBase.utime"
          :value="`（${formBase.uuserName} - ${formBase.utime}）`"
        />
        <template v-if="formBase.openAnalysis">
          <div class="analysis-separate">
            <van-icon
              class="iconfont iconJSA1"
              class-prefix="iconfont"
              name="JSA1"
            />{{ TEXT_ANALYSIS_MODULE }}
            <span v-if="formBase.jsaCode" style="font-size: 14px;"
              >编号：{{ formBase.jsaCode }}</span
            >
          </div>
          <div class="separate"></div>
          <div class="group-title-box">
            <div class="title">
              个人防护用品
            </div>
          </div>
          <CheckboxEditField
            v-model="formBase.protectiveEquipment"
            :multiple="true"
            :editing="editable"
            :options="personalProtectionOptionts"
            option-value-key="id"
            option-label-key="name"
          >
            <template slot="tagContent" slot-scope="scope">
              <div class="pp-tag-content">
                <span>{{ scope.item.name }}</span>
                <van-icon
                  v-if="scope.item.delete"
                  class="pp-option-close"
                  name="clear"
                  @click.stop="ppOptionDelete(scope.item.id)"
                />
              </div>
            </template>
            <Tag v-if="editable" type="white" @click.native="ppOptionAdd">
              <van-icon name="plus" />
            </Tag>
          </CheckboxEditField>
          <div class="separate"></div>
          <div class="group-title-box">
            <div class="title">
              {{ TEXT_ANALYSIS_TABLE
              }}<span v-if="editable" class="title-2">长按拖动步骤</span>
            </div>
          </div>
          <div v-if="safetyAnalyTable.length" class="sa-box">
            <div class="title-row">
              <span>工作步骤、危害描述、控制措施</span>
              <span>执行人</span>
              <span v-if="editable">操作</span>
            </div>
            <div class="list-box">
              <draggable
                v-model="safetyAnalyTable"
                draggable=".item-box"
                :delay="1500"
                :disabled="!editable"
              >
                <div
                  v-for="(row, key) in safetyAnalyTable"
                  :key="key"
                  class="item-wrap"
                >
                  <div class="item-box">
                    <div v-if="editable" class="left">
                      <van-checkbox
                        v-model="row.choose"
                        icon-size="16px"
                        shape="square"
                      ></van-checkbox>
                    </div>
                    <div class="middle">
                      <div
                        class="sub-item-btn"
                        @click="toggleSubItem(row, key)"
                      >
                        <i
                          class="iconfont"
                          :class="
                            row.showSubItem
                              ? 'iconfont-arrow-up'
                              : 'iconfont-arrow-down'
                          "
                        ></i>
                        <!--                        {{ !row.showSubItem ? "展开详情" : "收起" }}-->
                      </div>
                      <p class="title">
                        {{ key + 1 }}、{{ getColumnValue(row, "step") }}
                        <span class="txt-red"
                          >({{ getColumnValue(row, "stepTypeCode") }})</span
                        >
                      </p>
                      <div class="harm-box">
                        <div class="label">危害：</div>
                        <div class="text">{{ row.jsaDescribe }}</div>
                      </div>
                      <div class="harm-box">
                        <div class="label">控制措施：</div>
                        <div class="text">{{ row.measures }}</div>
                      </div>
                    </div>
                    <div class="right" :class="{ signature: signatureSwitch }">
                      <template v-if="signatureSwitch">
                        <div v-for="p in row.executorList" :key="p.id">
                          <Tag
                            :type="p.fileId ? 'green' : 'white'"
                            :tick="!!p.fileId"
                            @click.native="signatureClick(p)"
                          >
                            {{ p.name }}
                          </Tag>
                        </div>
                      </template>
                      <template v-else>
                        <div v-for="p in row.executorList" :key="p.id">
                          <span>{{ p.name }}</span>
                        </div>
                      </template>
                    </div>
                    <div v-if="editable" class="operation">
                      <div>
                        <span
                          class="delete-btn iconfont iconfont-bianji"
                          @click="editSafetyAnalyTable(row, key)"
                        ></span>
                      </div>
                      <div>
                        <span
                          class="delete-btn iconfont iconfont-shanchu2"
                          @click="deleteSafetyAnalyTable(key)"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div v-if="row.showSubItem" class="sub-item-box">
                    <van-row type="flex">
                      <van-col span="12">评价方法</van-col>
                      <van-col span="12">{{
                        getColumnValue(row, "evaluationMethod")
                      }}</van-col>
                    </van-row>
                    <van-row type="flex">
                      <van-col>固有风险分析</van-col>
                    </van-row>
                    <div>
                      <template v-if="tableEvaluationMethod === '1'">
                        <van-row type="flex">
                          <van-col span="8"
                            >L:
                            {{ getColumnValue(row, "inherentLsL") }}</van-col
                          >
                          <van-col span="8"
                            >S:
                            {{ getColumnValue(row, "inherentLsS") }}</van-col
                          >
                          <van-col span="8"
                            >R:
                            {{ getColumnValue(row, "inherentLsR") }}</van-col
                          >
                        </van-row>
                      </template>
                      <template v-else>
                        <van-row type="flex">
                          <van-col span="12"
                            >L:
                            {{ getColumnValue(row, "inherentLecL") }}</van-col
                          >
                          <van-col span="12"
                            >E:
                            {{ getColumnValue(row, "inherentLecE") }}</van-col
                          >
                          <van-col span="12"
                            >C:
                            {{ getColumnValue(row, "inherentLecC") }}</van-col
                          >
                          <van-col span="12"
                            >D:
                            {{ getColumnValue(row, "inherentLecD") }}</van-col
                          >
                        </van-row>
                      </template>
                    </div>
                    <van-row type="flex">
                      <van-col span="12"
                        >风险等级:
                        {{
                          getColumnValue(row, "inherentRiskLevelCode")
                        }}</van-col
                      >
                      <van-col span="12"
                        >管控层级:
                        {{
                          getColumnValue(row, "inherentGovernanceLevel")
                        }}</van-col
                      >
                    </van-row>
                    <van-row type="flex">
                      <van-col>剩余风险评价</van-col>
                    </van-row>
                    <template v-if="tableEvaluationMethod === '1'">
                      <van-row type="flex">
                        <van-col span="8"
                          >L: {{ getColumnValue(row, "lsL") }}</van-col
                        >
                        <van-col span="8"
                          >S: {{ getColumnValue(row, "lsS") }}</van-col
                        >
                        <van-col span="8"
                          >R: {{ getColumnValue(row, "lsR") }}</van-col
                        >
                      </van-row>
                    </template>
                    <template v-else>
                      <van-row type="flex">
                        <van-col span="12"
                          >L: {{ getColumnValue(row, "lecL") }}</van-col
                        >
                        <van-col span="12"
                          >E: {{ getColumnValue(row, "lecE") }}</van-col
                        >
                        <van-col span="12"
                          >C: {{ getColumnValue(row, "lecC") }}</van-col
                        >
                        <van-col span="12"
                          >D: {{ getColumnValue(row, "lecD") }}</van-col
                        >
                      </van-row>
                    </template>
                    <van-row type="flex">
                      <van-col span="12"
                        >风险等级:
                        {{ getColumnValue(row, "riskLevelCode") }}</van-col
                      >
                      <van-col span="12"
                        >管控层级:
                        {{ getColumnValue(row, "governanceLevel") }}</van-col
                      >
                    </van-row>
                    <van-row type="flex">
                      <van-col span="12">是否接受</van-col>
                      <van-col span="12">{{
                        getColumnValue(row, "acceptStatus")
                      }}</van-col>
                    </van-row>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <div v-if="editable" class="group-title-box">
            <p class="sa-btn-box" @click="addWorkStep">
              <span
                class="iconfont iconfont-tianjiagongzuobuzhou"
                style="color: #1676ff"
              />
              <span>添加工作步骤</span>
            </p>
            <van-button
              v-if="safetyAnalyTable.length"
              class="choose-temp-btn"
              round
              size="small"
              type="info"
              native-type="button"
              @click="setSATableExecutorPerson()"
              >选择执行人</van-button
            >
            <select-person-by-tabs
              title="执行人(多选)"
              :visible="showExecutorPicker"
              :show-department-name="false"
              :show-phone-number="true"
              position="bottom"
              :multiple="true"
              :is-department-auth="false"
              :default-department="formBase.department"
              :show-recent-use-person="true"
              recent-type="1"
              :default-tab-name="2"
              @close="showExecutorPicker = false"
              @confirm="onExecutorPersonConfirm"
            />
          </div>
          <div class="separate"></div>
          <div class="group-title-box">
            <div class="title">{{ projectTextConfig.TEXT_WORK_DEPT }}签字</div>
          </div>
          <div
            v-for="(wt, idx) in completeWorkTypeList"
            :key="`${idx}${wt.id}`"
            class="wt-container"
          >
            <div class="wt-title">
              <span class="color-tag" :class="colorTagSwitch(wt)"></span>
              <p class="text">
                <span class="type">{{ wt.name }}</span>
                <span class="company">{{ wt.deptName }}</span>
              </p>
            </div>
            <div class="wt-form">
              <van-field
                class="custom-input-person"
                readonly
                :clickable="editable"
                :is-link="editable"
                :value="
                  getPersonLabel(formBase.workTypeList[idx].workerList.leader)
                "
                name="leader"
                label="作业负责人"
                placeholder="请选择人员"
                v-bind="fieldProps"
                :required="editable"
                :rules="[
                  {
                    required: dynamicRule,
                    message: '请选择作业负责人',
                    trigger: 'onChange'
                  }
                ]"
                @click="personPickerWake(`作业负责人personPicker${idx}`)"
              >
                <template #input>
                  <div>
                    <Tag
                      v-if="signatureSwitch"
                      :type="
                        formBase.workTypeList[idx].workerList.leader.fileId
                          ? 'green'
                          : 'white'
                      "
                      :tick="
                        !!formBase.workTypeList[idx].workerList.leader.fileId
                      "
                      @click.native="
                        signatureClick(
                          formBase.workTypeList[idx].workerList.leader
                        )
                      "
                    >
                      {{ formBase.workTypeList[idx].workerList.leader.label }}
                    </Tag>
                    <p v-else>
                      <span class="person-box">
                        {{ formBase.workTypeList[idx].workerList.leader.label }}
                      </span>
                    </p>
                  </div>
                </template>
              </van-field>
              <WorkticketPersonPicker
                :ref="`作业负责人personPicker${idx}`"
                v-model="formBase.workTypeList[idx].workerList.leader"
                title="作业负责人"
                :work-type-id="wt.id"
                :contractor-status="wt.contractorStatus"
                :dept-code="wt.deptCode"
                @change="WPPersonChange(idx)"
              />
              <template v-if="wt.specialJobType !== '5'">
                <van-field
                  class="custom-input-person"
                  readonly
                  :disabled="wt.specialJobType === WORKTICKET_TYPE.hoisting"
                  :is-link="editable"
                  :clickable="editable"
                  autosize
                  :value="
                    getPersonLabel(
                      formBase.workTypeList[idx].workerList.workerList
                    )
                  "
                  name="workerList"
                  label="特种作业人"
                  placeholder="请选择人员"
                  v-bind="fieldProps"
                  :rules="[
                    {
                      required:
                        formBase.workTypeList[idx].licenseType &&
                        formBase.workTypeList[idx].licenseType.length > 0,
                      message: '请选择作业人',
                      trigger: 'onChange'
                    }
                  ]"
                  @click="workerClick(wt, idx)"
                >
                  <template #input>
                    <div>
                      <template v-if="signatureSwitch">
                        <Tag
                          v-for="p in formBase.workTypeList[idx].workerList
                            .workerList"
                          :key="p.id"
                          :type="p.fileId ? 'green' : 'white'"
                          :tick="!!p.fileId"
                          @click.native="signatureClick(p)"
                        >
                          {{ p.label }}
                        </Tag>
                      </template>
                      <template v-else>
                        <span
                          v-for="p in formBase.workTypeList[idx].workerList
                            .workerList"
                          :key="p.id"
                        >
                          <span class="person-box">{{ p.label }}</span>
                        </span>
                      </template>
                    </div>
                  </template>
                </van-field>
                <!-- <WorkticketPersonPicker
                  :ref="`特种作业人personPicker${idx}`"
                  v-model="formBase.workTypeList[idx].workerList.workerList"
                  title="特种作业人"
                  :work-type-id="wt.id"
                  :multiple="true"
                  :contractor-status="wt.contractorStatus"
                  :dept-code="wt.deptCode"
                  :is-hoisting="true"
                  :show-license-tag="true"
                  :license-types="
                    formBase.workTypeList[idx].licenseType.replace(/,/g, '-')
                  "
                  @change="WPPersonChange(idx)"
                /> -->
                <select-person-by-tabs
                  :key="`workerList${workerPickerVisible}`"
                  title="特种作业人(多选)"
                  :visible="workerPickerVisible"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :include-children="true"
                  :multiple="true"
                  :is-department-auth="false"
                  :default-department="formBase.department"
                  :default-contractor="wt.contractorStatus ? wt.deptCode : ''"
                  :choose-value="
                    formBase.workTypeList[idx].workerList.workerList
                  "
                  :license-types="
                    licenseTypeFormatter(
                      formBase.workTypeList[idx] &&
                        formBase.workTypeList[idx].licenseType
                    )
                  "
                  :contractor-license-types="
                    licenseTypeFormatter(
                      formBase.workTypeList[idx] &&
                        formBase.workTypeList[idx].licenseType
                    )
                  "
                  work-type-id=""
                  recent-type="3"
                  contractor-worker-type="3"
                  :show-recent-use-person="true"
                  :default-tab-name="2"
                  @close="workerPickerVisible = false"
                  @confirm="
                    (ids, list) => {
                      onPersonConfirm(ids, list, 'workerList');
                      WPPersonChange(idx);
                    }
                  "
                />
              </template>
              <template v-if="wt.specialJobType !== '5'">
                <van-field
                  class="custom-input-person"
                  readonly
                  :disabled="wt.specialJobType === WORKTICKET_TYPE.hoisting"
                  :is-link="editable"
                  :clickable="editable"
                  autosize
                  :value="
                    getPersonLabel(
                      formBase.workTypeList[idx].workerList.ordinaryWorkerList
                    )
                  "
                  name="ordinaryWorkerList"
                  label="一般作业人"
                  placeholder="请选择人员"
                  v-bind="fieldProps"
                  :rules="[
                    {
                      required: !formBase.workTypeList[idx].licenseType,
                      message: '请选择一般作业人',
                      trigger: 'onChange'
                    }
                  ]"
                  @click="oridnaryWorkerClick(wt, idx)"
                >
                  <template #input>
                    <div>
                      <template v-if="signatureSwitch">
                        <Tag
                          v-for="p in formBase.workTypeList[idx].workerList
                            .ordinaryWorkerList"
                          :key="p.id"
                          :type="p.fileId ? 'green' : 'white'"
                          :tick="!!p.fileId"
                          @click.native="signatureClick(p)"
                        >
                          {{ p.label }}
                        </Tag>
                      </template>
                      <template v-else>
                        <span
                          v-for="p in formBase.workTypeList[idx].workerList
                            .ordinaryWorkerList"
                          :key="p.id"
                        >
                          <span class="person-box">{{ p.label }}</span>
                        </span>
                      </template>
                    </div>
                  </template>
                </van-field>
                <!-- <WorkticketPersonPicker
                  :ref="`一般作业人personPicker${idx}`"
                  v-model="
                    formBase.workTypeList[idx].workerList.ordinaryWorkerList
                  "
                  title="一般作业人"
                  :work-type-id="wt.id"
                  :multiple="true"
                  :contractor-status="wt.contractorStatus"
                  :dept-code="wt.deptCode"
                  :is-hoisting="true"
                  :show-license-tag="true"
                  @change="WPPersonChange(idx)"
                /> -->
                <select-person-by-tabs
                  :key="`ordinaryWorkerList${normalPersonPickerVisible}`"
                  title="一般作业人(多选)"
                  :visible="normalPersonPickerVisible"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :include-children="true"
                  :multiple="true"
                  :is-department-auth="false"
                  :default-department="formBase.department"
                  :default-contractor="wt.contractorStatus ? wt.deptCode : ''"
                  :choose-value="
                    formBase.workTypeList[idx].workerList.ordinaryWorkerList
                  "
                  license-types=""
                  work-type-id=""
                  recent-type="15"
                  contractor-worker-type="15"
                  :show-recent-use-person="true"
                  :default-tab-name="2"
                  @close="normalPersonPickerVisible = false"
                  @confirm="
                    (ids, list) => {
                      onPersonConfirm(ids, list, 'ordinaryWorkerList');
                      WPPersonChange(idx);
                    }
                  "
                />
              </template>
              <template v-if="wt.specialJobType === '5'">
                <van-field
                  class="custom-input-person"
                  readonly
                  :is-link="editable"
                  :clickable="editable"
                  autosize
                  :value="
                    hoistingGetPersonLabel(
                      formBase.workTypeList[idx].workerList.hoistingCommandList
                    )
                  "
                  name="hoistingCommandList"
                  label="指挥人员"
                  placeholder=""
                  v-bind="fieldProps"
                  @click="editable && (hoistingCommandListVisible = true)"
                >
                  <template #input>
                    <template v-if="signatureSwitch">
                      <div>
                        <Tag
                          v-for="p in formBase.workTypeList[idx].workerList
                            .hoistingCommandList"
                          :key="p.id"
                          :type="p.fileId ? 'green' : 'white'"
                          :tick="!!p.fileId"
                          @click.native="signatureClick(p)"
                        >
                          {{ p.label }}
                        </Tag>
                      </div>
                    </template>
                    <template v-else>
                      <span
                        v-for="p in formBase.workTypeList[idx].workerList
                          .hoistingCommandList"
                        :key="p.id"
                      >
                        <span class="person-box">{{ p.label }}</span>
                      </span>
                    </template>
                  </template>
                </van-field>
                <!-- <WorkticketPersonPicker
                  :ref="`指挥人员personPicker${idx}`"
                  v-model="
                    formBase.workTypeList[idx].workerList.hoistingCommandList
                  "
                  title="指挥人员"
                  :multiple="true"
                  :work-type-id="wt.id"
                  :contractor-status="wt.contractorStatus"
                  :dept-code="wt.deptCode"
                  :is-hoisting="true"
                  :show-license-tag="true"
                  :license-types="licenseTypesSwitch(wt, 11)"
                  @change="hositingPersonConcat(idx)"
                /> -->
                <select-person-by-tabs
                  title="指挥人员(多选)"
                  :visible="hoistingCommandListVisible"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :include-children="true"
                  :multiple="true"
                  :is-department-auth="false"
                  :default-department="formBase.department"
                  :default-contractor="wt.contractorStatus ? wt.deptCode : ''"
                  :choose-value="
                    formBase.workTypeList[idx].workerList.hoistingCommandList
                  "
                  :license-types="licenseTypesSwitch(wt, 11)"
                  recent-type="11"
                  contractor-worker-type="11"
                  :work-type-id="wt.id"
                  :show-recent-use-person="true"
                  :default-tab-name="2"
                  @close="hoistingCommandListVisible = false"
                  @confirm="
                    (ids, list) =>
                      onPersonConfirm(ids, list, 'hoistingCommandList')
                  "
                />
                <van-field
                  class="custom-input-person"
                  readonly
                  :is-link="editable"
                  :clickable="editable"
                  autosize
                  :value="
                    hoistingGetPersonLabel(
                      formBase.workTypeList[idx].workerList.hoistingPersonList
                    )
                  "
                  name="hoistingPersonList"
                  label="吊装作业人"
                  placeholder=""
                  v-bind="fieldProps"
                  @click="editable && (hoistingPersonListVisible = true)"
                >
                  <template #input>
                    <template v-if="signatureSwitch">
                      <div>
                        <Tag
                          v-for="p in formBase.workTypeList[idx].workerList
                            .hoistingPersonList"
                          :key="p.id"
                          :type="p.fileId ? 'green' : 'white'"
                          :tick="!!p.fileId"
                          @click.native="signatureClick(p)"
                        >
                          {{ p.label }}
                        </Tag>
                      </div>
                    </template>
                    <template v-else>
                      <span
                        v-for="p in formBase.workTypeList[idx].workerList
                          .hoistingPersonList"
                        :key="p.id"
                      >
                        <span class="person-box">{{ p.label }}</span>
                      </span>
                    </template>
                  </template>
                </van-field>
                <!-- <WorkticketPersonPicker
                  :ref="`吊装作业人personPicker${idx}`"
                  v-model="
                    formBase.workTypeList[idx].workerList.hoistingPersonList
                  "
                  title="吊装作业人"
                  :multiple="true"
                  :work-type-id="wt.id"
                  :contractor-status="wt.contractorStatus"
                  :dept-code="wt.deptCode"
                  :is-hoisting="true"
                  :show-license-tag="true"
                  :license-types="licenseTypesSwitch(wt, 12)"
                  @change="hositingPersonConcat(idx)"
                /> -->
                <select-person-by-tabs
                  title="吊装作业人(多选)"
                  :visible="hoistingPersonListVisible"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :include-children="true"
                  :multiple="true"
                  :is-department-auth="false"
                  :default-department="formBase.department"
                  :default-contractor="wt.contractorStatus ? wt.deptCode : ''"
                  :choose-value="
                    formBase.workTypeList[idx].workerList.hoistingPersonList
                  "
                  :license-types="licenseTypesSwitch(wt, 12)"
                  recent-type="12"
                  contractor-worker-type="12"
                  :work-type-id="wt.id"
                  :show-recent-use-person="true"
                  :default-tab-name="2"
                  @close="hoistingPersonListVisible = false"
                  @confirm="
                    (ids, list) =>
                      onPersonConfirm(ids, list, 'hoistingPersonList')
                  "
                />
              </template>
              <van-field
                class="custom-input-person"
                readonly
                :clickable="editable"
                :is-link="editable"
                autosize
                :value="
                  getPersonLabel(
                    formBase.workTypeList[idx].workerList.guarderList
                  )
                "
                name="guarderList"
                label="监护人"
                placeholder="请选择人员"
                v-bind="fieldProps"
                :required="editable"
                :rules="[
                  {
                    required: dynamicRule,
                    message: '请选择监护人',
                    trigger: 'onChange'
                  }
                ]"
                @click="showSelectGuarder(idx)"
              >
                <template #input>
                  <template v-if="signatureSwitch">
                    <div>
                      <Tag
                        v-for="p in formBase.workTypeList[idx].workerList
                          .guarderList"
                        :key="p.id"
                        :type="p.fileId ? 'green' : 'white'"
                        :tick="!!p.fileId"
                        @click.native="signatureClick(p)"
                      >
                        {{ p.label }}
                      </Tag>
                    </div>
                  </template>
                  <template v-else>
                    <p>
                      <span
                        v-for="p in formBase.workTypeList[idx].workerList
                          .guarderList"
                        :key="p.id"
                        class="person-box"
                      >
                        {{ p.label }}
                      </span>
                    </p>
                  </template>
                </template>
              </van-field>
              <select-person-by-tabs
                title="监护人(多选)"
                :visible="showGuarderPicker[idx]"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :multiple="true"
                :is-department-auth="false"
                :include-children="true"
                :default-department="formBase.department"
                :default-contractor="wt.contractorStatus ? wt.deptCode : ''"
                :choose-value="
                  formBase.workTypeList[idx].workerList.guarderList
                "
                :license-types="licenseTypesSwitch(wt, 2)"
                :work-type-id="wt.id"
                :show-recent-use-person="true"
                recent-type="2"
                :default-tab-name="2"
                @close="showGuarderPicker.splice(idx, 1, false)"
                @confirm="
                  (ids, list) => onPersonConfirm(ids, list, 'guarderList')
                "
              />
              <template v-if="wt.gasMonitor === 1">
                <van-field
                  class="custom-input-person"
                  :value="
                    getPersonLabel(
                      formBase.workTypeList[idx].workerList.analyst
                    )
                  "
                  :label="TEXT_WORK_ANALYST"
                  placeholder="请选择人员"
                  :clickable="editable"
                  :is-link="editable"
                  readonly
                  v-bind="fieldProps"
                  @click="showSelectAnalyst(idx)"
                >
                  <template #input>
                    <div>
                      <Tag
                        v-if="
                          signatureSwitch &&
                            formBase.workTypeList[idx].workerList.analyst.label
                        "
                        :type="
                          formBase.workTypeList[idx].workerList.analyst.fileId
                            ? 'green'
                            : 'white'
                        "
                        :tick="
                          !!formBase.workTypeList[idx].workerList.analyst.fileId
                        "
                        @click.native="
                          signatureClick(
                            formBase.workTypeList[idx].workerList.analyst
                          )
                        "
                      >
                        {{
                          formBase.workTypeList[idx].workerList.analyst.label
                        }}
                      </Tag>
                      <p v-else>
                        <span class="person-box">
                          {{
                            formBase.workTypeList[idx].workerList.analyst.label
                          }}
                        </span>
                      </p>
                    </div>
                  </template>
                </van-field>
                <!-- 其他组件都会正常相应参数刷新，select-person组件还未测试 -->
                <select-person
                  :title="`${TEXT_WORK_ANALYST}(单选)`"
                  :visible="showAnalystPicker[idx]"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :include-children="true"
                  :multiple="false"
                  :is-department-auth="false"
                  :default-department="formBase.department"
                  :license-types="licenseTypesSwitch(wt, 5)"
                  :choose-value="[
                    formBase.workTypeList[idx].workerList.analyst
                  ]"
                  @close="showAnalystPicker.splice(idx, 1, false)"
                  @confirm="
                    (ids, list) => onPersonConfirm(ids, list, 'analyst')
                  "
                />
              </template>
              <!-- <template v-if="wt.specialJobType === '4'">
                <van-field
                  class="custom-input-person"
                  :value="
                    getPersonLabel(
                      formBase.workTypeList[idx].workerList
                        .productionUnitCommander
                    )
                  "
                  name="productionUnitCommander"
                  :label="TEXT_PRODUCTION_UNIT_WORK_COMMANDER"
                  placeholder="请选择人员"
                  :clickable="editable"
                  :is-link="editable"
                  readonly
                  v-bind="fieldProps"
                  @click="showSelectPUC(idx)"
                >
                  <template #input>
                    <div>
                      <Tag
                        v-if="
                          signatureSwitch &&
                            formBase.workTypeList[idx].workerList
                              .productionUnitCommander.label
                        "
                        :type="
                          formBase.workTypeList[idx].workerList
                            .productionUnitCommander.fileId
                            ? 'green'
                            : 'white'
                        "
                        :tick="
                          !!formBase.workTypeList[idx].workerList
                            .productionUnitCommander.fileId
                        "
                        @click.native="
                          signatureClick(
                            formBase.workTypeList[idx].workerList
                              .productionUnitCommander
                          )
                        "
                      >
                        {{
                          formBase.workTypeList[idx].workerList
                            .productionUnitCommander.label
                        }}
                      </Tag>
                      <p v-else>
                        <span class="person-box">
                          {{
                            formBase.workTypeList[idx].workerList
                              .productionUnitCommander.label
                          }}
                        </span>
                      </p>
                    </div>
                  </template>
                </van-field>
                <select-person
                  :title="`${TEXT_PRODUCTION_UNIT_WORK_COMMANDER}(单选)`"
                  :visible="showPUCPicker[idx]"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :include-children="true"
                  :multiple="false"
                  :is-department-auth="false"
                  :default-department="formBase.department"
                  :license-types="licenseTypesSwitch(wt, 13)"
                  :choose-value="[
                    formBase.workTypeList[idx].workerList
                      .productionUnitCommander
                  ]"
                  @close="showPUCPicker.splice(idx, 1, false)"
                  @confirm="
                    (ids, list) =>
                      onPersonConfirm(ids, list, 'productionUnitCommander')
                  "
                />
              </template> -->
            </div>
          </div>
          <div class="separate"></div>
          <div class="group-title-box">
            <div class="title">审核与签字</div>
          </div>
          <van-field
            class="custom-input-person"
            readonly
            :clickable="editable"
            :is-link="editable"
            autosize
            :value="getPersonLabel(formBase.jsaLeaderList)"
            name="jsaLeaderList"
            label="JSA组长"
            placeholder="请选择人员"
            v-bind="fieldProps"
            required
            :rules="[
              {
                required: dynamicRule,
                message: '请选择JSA组长',
                trigger: 'onChange'
              }
            ]"
            @click="editable && (showJSALeaderPicker = true)"
          >
            <template #input>
              <div>
                <template v-if="signatureSwitch">
                  <Tag
                    v-for="p in formBase.jsaLeaderList"
                    :key="p.id"
                    :type="p.fileId ? 'green' : 'white'"
                    :tick="!!p.fileId"
                    @click.native="signatureClick(p)"
                  >
                    {{ p.name }}
                  </Tag>
                </template>
                <template v-else>
                  <span
                    v-for="p in formBase.jsaLeaderList"
                    :key="p.id"
                    class="person-box"
                    >{{ p.name }}</span
                  >
                </template>
              </div>
            </template>
          </van-field>
          <select-person-by-tabs
            title="JSA组长(单选)"
            :visible="showJSALeaderPicker"
            :show-department-name="false"
            :show-phone-number="true"
            position="bottom"
            :include-children="true"
            :multiple="false"
            :is-department-auth="false"
            :default-department="formBase.department"
            :choose-value="formBase.jsaLeaderList"
            contractor-worker-type="0"
            :show-recent-use-person="true"
            recent-type="3"
            :default-tab-name="2"
            @close="showJSALeaderPicker = false"
            @confirm="
              (ids, list) =>
                onPersonConfirmByCheckSignature(ids, list, 'jsaLeaderList')
            "
          />
          <van-field
            class="custom-input-person"
            :value="getPersonLabel(formBase.jsaApproverList)"
            name="jsaApproverList"
            label="JSA审核"
            placeholder="请选择人员"
            :clickable="editable"
            :is-link="editable"
            readonly
            v-bind="fieldProps"
            required
            :rules="[
              {
                required: dynamicRule,
                message: '请选择JSA审核',
                trigger: 'onChange'
              }
            ]"
            @click="editable && (showJsaCheckerPicker = true)"
          >
            <template #input>
              <div>
                <!-- <Tag
                  v-if="signatureSwitch"
                  :type="formBase.jsaApprover.fileId ? 'green' : 'white'"
                  :tick="!!formBase.jsaApprover.fileId"
                  @click.native="signatureClick(formBase.jsaApprover)"
                >
                  {{ formBase.jsaApprover.name }}
                </Tag>
                <p v-else>
                  <span class="person-box">
                    {{ formBase.jsaApprover.name }}
                  </span>
                </p> -->
                <template v-if="signatureSwitch">
                  <Tag
                    v-for="p in formBase.jsaApproverList"
                    :key="p.id"
                    :type="p.fileId ? 'green' : 'white'"
                    :tick="!!p.fileId"
                    @click.native="signatureClick(p)"
                  >
                    {{ p.name }}
                  </Tag>
                </template>
                <template v-else>
                  <span
                    v-for="p in formBase.jsaApproverList"
                    :key="p.id"
                    class="person-box"
                    >{{ p.name }}</span
                  >
                </template>
              </div>
            </template>
          </van-field>
          <select-person-by-tabs
            title="JSA审核(多选)"
            :visible="showJsaCheckerPicker"
            :show-department-name="false"
            :show-phone-number="true"
            position="bottom"
            :include-children="true"
            :multiple="true"
            :is-department-auth="false"
            :default-department="formBase.department"
            :show-contractor-person="false"
            recent-type="4"
            :show-recent-use-person="true"
            :default-tab-name="1"
            :choose-value="formBase.jsaApproverList"
            @close="showJsaCheckerPicker = false"
            @confirm="
              (ids, list) =>
                onPersonConfirmByCheckSignature(ids, list, 'jsaApproverList')
            "
          />
          <van-field
            class="custom-input-person"
            :value="getPersonLabel(formBase.jsaRelateList)"
            name="jsaRelateList"
            label="分析人员"
            placeholder="请选择人员"
            :clickable="editable"
            :is-link="editable"
            readonly
            v-bind="fieldProps"
            @click="editable && (showOtherPicker = true)"
          >
            <template #input>
              <div>
                <template v-if="signatureSwitch">
                  <Tag
                    v-for="p in formBase.jsaRelateList"
                    :key="p.id"
                    :type="p.fileId ? 'green' : 'white'"
                    :tick="!!p.fileId"
                    @click.native="signatureClick(p)"
                  >
                    {{ p.name }}
                  </Tag>
                </template>
                <template v-else>
                  <span
                    v-for="p in formBase.jsaRelateList"
                    :key="p.id"
                    class="person-box"
                    >{{ p.name }}</span
                  >
                </template>
              </div>
            </template>
          </van-field>
          <select-person-by-tabs
            title="分析人员(多选)"
            :visible="showOtherPicker"
            :show-department-name="false"
            :show-phone-number="true"
            position="bottom"
            :multiple="true"
            :is-department-auth="false"
            :default-department="formBase.department"
            recent-type="5"
            :show-recent-use-person="true"
            :default-tab-name="2"
            :choose-value="formBase.jsaRelateList"
            @close="showOtherPicker = false"
            @confirm="
              (ids, list) =>
                onPersonConfirmByCheckSignature(ids, list, 'jsaRelateList')
            "
          />
          <div v-if="formBase.status === 3">
            <div class="separate"></div>
            <div class="group-title-box">
              <div class="title">总结与反馈</div>
            </div>
            <div class="feedback-box">
              <p class="feedback-content">{{ formBase.jsaFeedBack }}</p>
              <p class="feedback-oper">
                （{{ formBase.uuserName }} - {{ formBase.utime }}）
              </p>
            </div>
          </div>
        </template>
      </van-form>
      <div class="footer-box">
        <!-- 新增状态，保存、提交 -->
        <template v-if="type === 'add'">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="pageSave()"
            >保 存</van-button
          >
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="pageSubmit()"
            >提 交</van-button
          >
        </template>
        <template v-if="checkoutUser">
          <!-- 待提交状态，保存、提交-->
          <template
            v-if="
              formBase.status === 0 &&
                editBtnAuth &&
                formBase.cuserId == userInfo.id
            "
          >
            <van-button
              class="confirm-btn"
              round
              size="small"
              type="info"
              @click="pageSave()"
              >保 存</van-button
            >
            <van-button
              class="confirm-btn"
              round
              size="small"
              type="info"
              @click="pageSubmit()"
              >提 交</van-button
            >
          </template>
          <!-- 提交后待签字状态，提交，退回-->
          <template v-if="formBase.status === 1">
            <van-button
              v-if="!isBy && editBtnAuth"
              class="confirm-btn"
              :class="{
                can: formBase.jsaApproverList.some(i => i.id === userInfo.id)
              }"
              round
              size="small"
              type="info"
              @click="reject()"
              >退 回</van-button
            >
            <van-button
              v-if="
                formBase[
                  submitPerson ? 'jsaLeaderList' : 'jsaApproverList'
                ].some(i => i.id === userInfo.id)
              "
              class="confirm-btn"
              :class="{ 'btn-disabled': signatureVerify }"
              round
              size="small"
              type="info"
              @click="pageSignatureSubmit()"
              >提 交</van-button
            >
          </template>
        </template>
        <!-- 待反馈 总结与反馈、导出 -->
        <template v-if="formBase.status === 2">
          <van-button
            class="confirm-btn san"
            round
            size="small"
            type="info"
            @click="sameAddWorkticket()"
            >批量建票</van-button
          >
          <van-button
            v-if="editBtnAuth && formBase.cuserId == userInfo.id"
            class="confirm-btn san"
            round
            size="small"
            type="info"
            @click="feedBack()"
            >总结与反馈</van-button
          >
          <van-button
            v-if="btnAuth.export"
            class="confirm-btn san"
            round
            size="small"
            type="info"
            @click="exportClick()"
            >导 出</van-button
          >
        </template>
        <!-- 完成 导出 -->
        <template v-if="btnAuth.export && formBase.status === 3">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="sameAddWorkticket()"
            >批量建票</van-button
          >
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="exportClick()"
            >导 出</van-button
          >
        </template>
        <template v-if="formBase.status === 5">
          <van-button
            v-if="formBase.approveStatus"
            class="btn"
            round
            size="small"
            type="info"
            @click="jsaApprovePass"
            >审批通过</van-button
          >
          <van-button
            v-if="formBase.approveStatus"
            class="btn"
            round
            size="small"
            type="info"
            @click="jsaApproveRollback"
            >退 回</van-button
          >
          <van-button
            v-if="formBase.recallStatus"
            class="btn"
            round
            size="small"
            type="info"
            @click="jsaApproveRecall"
            >撤 回</van-button
          >
          <van-button
            v-if="btnAuth.export"
            :class="[isBy ? 'btn' : 'confirm-btn san']"
            round
            size="small"
            type="info"
            @click="exportClick()"
            >导 出</van-button
          >
        </template>
      </div>
    </div>
    <!--    <WorkAreaPicker-->
    <!--      ref="workAreaPicker"-->
    <!--      v-model="formBase.facilityId"-->
    <!--      @change="workAreaChange"-->
    <!--    />-->
    <add-facility
      v-model="facilityMap"
      :visible="facilityVisible"
      :department="formBase.department"
      :includes-department="true"
      :facility="[16, 13, 14, 15, 31, 17]"
      activated="16"
      :title="projectTextConfig.TEXT_FACILITY_NAME"
      auth="org"
      @close="facilityVisible = false"
      @confirm="onFacilityConfirm"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        type="datetime"
        @confirm="onDateimeConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
    <van-popup
      v-model="showWorkTypePicker"
      class="work-type-popup"
      position="bottom"
      @close="workTypePickerClose()"
    >
      <section class="header">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="closeIconClick()"
        />
        <span class="title">添加作业类别</span>
      </section>
      <section class="content">
        <van-form ref="addWorkTypeForm" class="add-work-type-form-box">
          <van-collapse v-model="addWorkTypeActiveNames">
            <van-collapse-item
              v-for="(item, idx) of workTypeList"
              :key="idx"
              class="collapse-item-box"
              :title="item.name"
              :name="idx"
              :is-link="false"
              :border="false"
            >
              <template #title>
                <div>
                  <van-checkbox
                    v-model="item.choose"
                    class="checkbox-box"
                    shape="square"
                    icon-size="16px"
                    :disabled="workTypeIdDisabled(item)"
                    @change="val => workTypeCheckboxChange(val, idx)"
                  ></van-checkbox>
                  <span
                    :class="[
                      'checkbox-name',
                      workTypeIdDisabled(item) && 'disabled'
                    ]"
                    >{{ item.name }}</span
                  >
                  <van-icon
                    v-if="workTypeIdDisabled(item)"
                    class="tips-icon"
                    name="question"
                    @click.stop="tipsVisible = true"
                  />
                </div>
              </template>
              <van-field
                readonly
                clickable
                is-link
                :value="item.deptName"
                name="deptName"
                :label="projectTextConfig.TEXT_WORK_DEPT"
                placeholder="请选择"
                required
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: item.choose,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="workUnitClick(item, idx)"
              />
              <van-field
                readonly
                clickable
                is-link
                :value="item.licenseTypeName.toString()"
                name="licenseTypeName"
                label="特种作业人员所需资质"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :disabled="item.specialJobType === '5'"
                @click="licenseTypeClick(item, idx)"
              />
              <van-notice-bar
                v-if="item.specialJobType === '6'"
                wrapable
                :scrollable="false"
                text=""
                background="rgba(0, 0, 0, 0.3)"
                color="#fff"
              >
                <p>
                  在<span class="wbsad-blue">生产装置区、罐区</span
                  >等危险场所动土时，遇有埋设的易燃易爆、有毒有害介质管线、窖井等可能<span
                    class="wbsad-blue"
                    >引起燃烧、爆炸、中毒、窒息危险</span
                  >，且挖掘深度超过<span class="wbsad-blue">1.2 m</span
                  >时，应执行<span class="wbsad-red">受限空间</span
                  >作业相关规定。
                </p>
              </van-notice-bar>

              <!-- 高处作业提示 -->
              <van-notice-bar
                v-if="item.specialJobType === '3'"
                wrapable
                :scrollable="false"
                text=""
                background="rgba(0, 0, 0, 0.3)"
                color="#fff"
              >
                <ElevationLevelTypeInfo />
              </van-notice-bar>
              <van-field
                v-if="isBy"
                readonly
                clickable
                is-link
                :value="
                  item.workTypePerson.map(item => item.personName).toString()
                "
                name="workTypePerson"
                label="人员"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                required
                :rules="[
                  {
                    required: item.choose,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="workTypePersonClick(item, idx)"
              />
            </van-collapse-item>
          </van-collapse>
        </van-form>
      </section>
      <div class="confirm-btn-box">
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="addWorkTypeConfirm()"
          >确 定</van-button
        >
      </div>
    </van-popup>
    <!-- 作业单位选择 -->
    <van-popup
      v-model="showWorkUnitPicker"
      class="work-unit-popup"
      position="bottom"
      @close="showWorkUnitPicker = false"
    >
      <div class="content">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="showWorkUnitPicker = false"
        />
        <div class="confirm-btn-box">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="workUnitConfirm()"
            >确 定</van-button
          >
        </div>
        <van-tabs v-model="workUnitActive">
          <van-tab name="1" title="承包商">
            <van-search
              v-model="keyword"
              placeholder="请输入关键词"
              @search="handleSearch"
              @clear="handleClear"
            >
              <template #left-icon>
                <van-icon
                  name="sousuo"
                  class-prefix="iconfont"
                  class="search-icon"
                />
              </template>
            </van-search>
            <div class="contractors-list">
              <div
                v-for="item in contractorFilterItems"
                :key="item.value"
                class="contractors-list-item"
                :class="{
                  active: item.value === contractorsChooseItem.value,
                  'active-bgc': item.value === contractorsChooseItem.value
                }"
                @click="contractorsChooseClick(item)"
              >
                <span>{{ item.label }}</span>
              </div>
            </div>
          </van-tab>
          <van-tab name="0" title="企业部门">
            <van-search
              v-model="filterString"
              placeholder="请输入关键词"
              @search="onFilter"
              @clear="onFilter"
            >
              <template #left-icon>
                <van-icon
                  name="sousuo"
                  class-prefix="iconfont"
                  class="search-icon"
                />
              </template>
            </van-search>
            <div class="org-tree-container">
              <org-tree
                v-model="deptSelectValue"
                :data="deptTreeData"
                label-key="label"
                value-key="id"
                disabled-key="disabled"
                disabled-toast="数据已存在"
                @input="deptTreeInput"
              />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </van-popup>
    <!-- 作业资质选择 -->
    <van-popup
      v-model="showLicensePicker"
      class="work-unit-popup"
      position="bottom"
      @close="showLicensePicker = false"
    >
      <div class="content">
        <p class="title">作业人员所需资质</p>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="showLicensePicker = false"
        />
        <div class="confirm-btn-box">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="licenseTypeConfirm()"
            >确 定</van-button
          >
        </div>
        <div class="org-tree-container">
          <org-tree
            v-model="licenseSelectValue"
            :data="licenseDictTreeData"
            label-key="name"
            value-key="id"
            disabled-key="disabled"
            choosable-key="choosable"
            @input="licenseTypeInput"
          />
        </div>
      </div>
    </van-popup>
    <!-- 添加工作步骤 -->
    <van-popup
      v-model="addWorkStepPopup"
      class="add-work-step-popup"
      position="bottom"
      @close="addWorkStepPopup = false"
    >
      <section class="header">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="addWorkStepPopup = false"
        />
        <span class="title"
          >{{ workStepType == "add" ? "添加" : "编辑" }}工作步骤</span
        >
      </section>
      <section class="content">
        <van-form ref="addWorkStepForm" class="add-work-type-form-box">
          <p class="label">动作类别</p>
          <van-field>
            <template #input>
              <van-radio-group
                v-model="analyzeTableRowForm.stepTypeCode"
                direction="horizontal"
              >
                <van-radio
                  v-for="(item, index) in classificationData"
                  :key="index"
                  :name="item.value"
                >
                  {{ item.label }}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <p class="label">动作步骤</p>
          <van-field
            v-model="analyzeTableRowForm.step"
            rows="2"
            autosize
            maxlength="32"
            placeholder="请输入动作步骤"
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
          />
          <p class="label">危害描述</p>
          <van-field
            v-model="analyzeTableRowForm.jsaDescribe"
            rows="2"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="请输入危害及可能造成的后果"
            show-word-limit
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
          />
          <p class="label">评价方法</p>
          <van-field>
            <template #input>
              <van-radio-group
                v-model="analyzeTableRowForm.evaluationMethod"
                direction="horizontal"
                :disabled="safetyAnalyTable.length > 0"
              >
                <van-radio
                  v-for="(item, index) in evaluationMethodData"
                  :key="index"
                  :name="item.value"
                >
                  {{ item.label }}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <p class="label">固有风险分析</p>
          <div class="grey-area">
            <template v-if="analyzeTableRowForm.evaluationMethod === '1'">
              <van-field
                :value="analyzeTableRowForm.inherentLsL"
                name="inherentLsL"
                is-link
                readonly
                :required="false"
                label="L"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="inherentLsLVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.inherentLsL"
                :data="LS_DATA"
                title="L"
                :visible="inherentLsLVisiable"
                @close="inherentLsLVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.inherentLsS"
                name="inherentLsS"
                is-link
                readonly
                :required="false"
                label="S"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="inherentLsSVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.inherentLsS"
                :data="LS_DATA"
                title="S"
                :visible="inherentLsSVisiable"
                @close="inherentLsSVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.inherentLsR"
                name="inherentLsR"
                readonly
                :required="false"
                label="R"
                placeholder="--"
                input-align="right"
                error-message-align="right"
              />
            </template>
            <template v-else>
              <van-field
                :value="analyzeTableRowForm.inherentLecL"
                name="inherentLecL"
                is-link
                readonly
                :required="false"
                label="L"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="inherentLecLVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.inherentLecL"
                :data="lecLTableData"
                title="L"
                :visible="inherentLecLVisiable"
                @close="inherentLecLVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.inherentLecE"
                name="inherentLecE"
                is-link
                readonly
                :required="false"
                label="E"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="inherentLecEVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.inherentLecE"
                :data="lecETableData"
                title="E"
                :visible="inherentLecEVisiable"
                @close="inherentLecEVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.inherentLecC"
                name="inherentLecC"
                is-link
                readonly
                :required="false"
                label="C"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="inherentLecCVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.inherentLecC"
                :data="lecCTableData"
                title="C"
                :visible="inherentLecCVisiable"
                @close="inherentLecCVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.inherentLecD"
                name="inherentLecD"
                readonly
                :required="false"
                label="D"
                placeholder="--"
                input-align="right"
                error-message-align="right"
              />
            </template>
            <van-field
              :value="
                getColumnValue(
                  analyzeTableRowForm,
                  'inherentRiskLevelCode',
                  false
                )
              "
              name="inherentRiskLevelCode"
              is-link
              readonly
              :required="false"
              label="风险等级"
              placeholder="请选择"
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: false,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
              @click="inherentRiskLevelCodeVisiable = true"
            />
            <select-popup
              v-model="analyzeTableRowForm.inherentRiskLevelCode"
              :data="RISK_LEVEL"
              title="风险等级"
              :visible="inherentRiskLevelCodeVisiable"
              @close="inherentRiskLevelCodeVisiable = false"
            />
            <van-field
              :value="
                getColumnValue(
                  analyzeTableRowForm,
                  'inherentGovernanceLevel',
                  false
                )
              "
              name="inherentGovernanceLevel"
              is-link
              readonly
              :required="false"
              label="管控层级"
              placeholder="请选择"
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: false,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
              @click="inherentGovernanceLevelVisiable = true"
            />
            <select-popup
              v-model="analyzeTableRowForm.inherentGovernanceLevel"
              :data="levelData"
              title="管控层级"
              :visible="inherentGovernanceLevelVisiable"
              @close="inherentGovernanceLevelVisiable = false"
            />
          </div>
          <p class="label">控制措施</p>
          <van-field
            v-model="analyzeTableRowForm.measures"
            rows="2"
            autosize
            type="textarea"
            maxlength="200"
            placeholder="请输入控制措施"
            show-word-limit
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
          />
          <p class="label">剩余风险分析</p>
          <div class="grey-area">
            <template v-if="analyzeTableRowForm.evaluationMethod === '1'">
              <van-field
                :value="analyzeTableRowForm.lsL"
                name="lsL"
                is-link
                readonly
                :required="false"
                label="L"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="lsLVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.lsL"
                :data="LS_DATA"
                title="L"
                :visible="lsLVisiable"
                @close="lsLVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.lsS"
                name="lsS"
                is-link
                readonly
                :required="false"
                label="S"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="lsSVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.lsS"
                :data="LS_DATA"
                title="S"
                :visible="lsSVisiable"
                @close="lsSVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.lsR"
                name="lsR"
                readonly
                :required="false"
                label="R"
                placeholder="--"
                input-align="right"
                error-message-align="right"
              />
            </template>
            <template v-else>
              <van-field
                :value="analyzeTableRowForm.lecL"
                name="lecL"
                is-link
                readonly
                :required="false"
                label="L"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="lecLVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.lecL"
                :data="lecLTableData"
                title="L"
                :visible="lecLVisiable"
                @close="lecLVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.lecE"
                name="lecE"
                is-link
                readonly
                :required="false"
                label="E"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="lecEVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.lecE"
                :data="lecETableData"
                title="E"
                :visible="lecEVisiable"
                @close="lecEVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.lecC"
                name="lecC"
                is-link
                readonly
                :required="false"
                label="C"
                placeholder="请选择"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="lecCVisiable = true"
              />
              <select-popup
                v-model="analyzeTableRowForm.lecC"
                :data="lecCTableData"
                title="C"
                :visible="lecCVisiable"
                @close="lecCVisiable = false"
              />
              <van-field
                :value="analyzeTableRowForm.lecD"
                name="lecD"
                readonly
                :required="false"
                label="D"
                placeholder="--"
                input-align="right"
                error-message-align="right"
              />
            </template>
            <van-field
              :value="
                getColumnValue(analyzeTableRowForm, 'riskLevelCode', false)
              "
              name="riskLevelCode"
              is-link
              readonly
              :required="false"
              label="风险等级"
              placeholder="请选择"
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: false,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
              @click="riskLevelCodeVisiable = true"
            />
            <select-popup
              v-model="analyzeTableRowForm.riskLevelCode"
              :data="RISK_LEVEL"
              title="风险等级"
              :visible="riskLevelCodeVisiable"
              @close="riskLevelCodeVisiable = false"
            />
            <van-field
              :value="
                getColumnValue(analyzeTableRowForm, 'governanceLevel', false)
              "
              name="governanceLevel"
              is-link
              readonly
              :required="false"
              label="管控层级"
              placeholder="请选择"
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: false,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
              @click="governanceLevelVisiable = true"
            />
            <select-popup
              v-model="analyzeTableRowForm.governanceLevel"
              :data="levelData"
              title="管控层级"
              :visible="governanceLevelVisiable"
              @close="governanceLevelVisiable = false"
            />
          </div>
          <p class="label">是否接受</p>
          <van-field>
            <template #input>
              <van-radio-group
                v-model="analyzeTableRowForm.acceptStatus"
                direction="horizontal"
              >
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            class="custom-input-person"
            readonly
            clickable
            is-link
            autosize
            :value="editExecutorSwitch"
            name="executorList"
            label="执行人"
            placeholder="请选择人员"
            v-bind="fieldProps"
            :rules="[
              {
                required: true,
                message: '请选择执行人',
                trigger: 'onChange'
              }
            ]"
            @click="editExecutorVisible = true"
          >
            <template #input>
              <p>
                <span
                  v-for="p in analyzeTableRowForm.executorList"
                  :key="p.id"
                  class="person-box"
                >
                  {{ p.label }}
                </span>
              </p>
            </template>
          </van-field>
          <select-person-by-tabs
            title="执行人(多选)"
            :visible="editExecutorVisible"
            :show-department-name="false"
            :show-phone-number="true"
            position="bottom"
            :multiple="true"
            :is-department-auth="false"
            :default-department="formBase.department"
            :show-recent-use-person="true"
            recent-type="1"
            :default-tab-name="2"
            :choose-value="analyzeTableRowForm.executorList"
            @close="editExecutorVisible = false"
            @confirm="editExecutorConfirm"
          />
        </van-form>
      </section>
      <div class="confirm-btn-box">
        <van-button
          class="cancel-btn"
          round
          size="small"
          type="info"
          @click="addWorkStepPopup = false"
          >取 消</van-button
        >
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="addWorkStepConfirm()"
          >确 定</van-button
        >
      </div>
    </van-popup>
    <!-- 退回原因 -->
    <van-popup
      v-model="rejectJsa"
      class="add-work-step-popup"
      position="bottom"
      @close="rejectJsa = false"
    >
      <section class="header">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="rejectJsa = false"
        />
        <span class="title">退回原因</span>
      </section>
      <section class="content">
        <van-form ref="rejectForm" class="add-work-type-form-box">
          <van-field
            v-model="rejectForm.rejectReason"
            rows="2"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="请输入退回原因"
            show-word-limit
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
          />
        </van-form>
      </section>
      <div class="confirm-btn-box">
        <van-button
          class="cancel-btn"
          round
          size="small"
          type="info"
          @click="rejectJsa = false"
          >取 消</van-button
        >
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          @click="rejectFormCofm()"
          >确 定</van-button
        >
      </div>
    </van-popup>
    <autocomplete
      title="作业名称"
      :visible="nameVisible"
      :params="{
        orgCode: formBase.orgCode,
        page: 1,
        size: 100
      }"
      :api="api"
      @confirm="onNameConfirm"
      @close="nameVisible = false"
    >
    </autocomplete>
    <cascade-department
      v-model="formBase.department"
      :visible="departmentVisible"
      disabled-key="--"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
    <van-popup
      v-model="SAWVisibi"
      class="work-type-popup saw-popup"
      position="bottom"
      @close="SAWVisibi = false"
    >
      <section class="header">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="SAWVisibi = false"
        />
        <span class="title">批量建票</span>
      </section>
      <section class="content">
        <van-form ref="sameAddWorkticketForm" class="add-work-type-form-box">
          <van-collapse v-model="SAWAddWorkTypeActiveNames">
            <van-collapse-item
              v-for="(item, idx) of SAWForm.workTypeList"
              :key="idx"
              class="collapse-item-box"
              :title="item.name"
              :name="idx"
              :is-link="false"
              :border="false"
            >
              <template #title>
                <div>
                  <van-checkbox
                    v-model="item.choose"
                    class="checkbox-box"
                    shape="square"
                    icon-size="16px"
                    @change="val => SAWWorkTypeCheckboxChange(val, idx)"
                  ></van-checkbox>
                  <span class="checkbox-name"
                    >{{ item.name }} ({{ item.deptName }})</span
                  >
                </div>
              </template>
              <van-field
                v-model="item.jsaName"
                name="name"
                :label="projectTextConfig.TEXT_JSA_NAME"
                :placeholder="`请输入${projectTextConfig.TEXT_JSA_NAME}`"
                required
                input-align="right"
                error-message-align="right"
                :rules="[
                  { required: item.choose, message: ' ', trigger: 'onBlur' }
                ]"
              />
              <van-field
                readonly
                is-link
                clickable
                :value="item.planStart"
                name="planStart"
                label="计划开始时间"
                placeholder="请选择"
                required
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: item.choose,
                    message: ' ',
                    trigger: 'onChange'
                  },
                  {
                    validator: val => SAWPlanStartValidator(val, idx),
                    message: '开始时间不能晚于结束时间',
                    trigger: 'onChange'
                  },
                  {
                    validator: val => planDateTimeoutValidator(val, item),
                    message: () => `${dateErrorMessage}`,
                    trigger: 'onChange'
                  }
                ]"
                @click="SAWPlanTimeClick('planStart', idx)"
              />
              <van-field
                readonly
                is-link
                clickable
                :value="item.planEnd"
                name="planEnd"
                label="计划结束时间"
                placeholder="请选择"
                required
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: item.choose,
                    message: ' ',
                    trigger: 'onChange'
                  },
                  {
                    validator: val => SAWPlanEndValidator(val, idx),
                    message: '结束时间不能早于开始时间',
                    trigger: 'onChange'
                  },
                  {
                    validator: val => planDateTimeoutValidator(val, item),
                    message: () => `${dateErrorMessage}`,
                    trigger: 'onChange'
                  }
                ]"
                @click="SAWPlanTimeClick('planEnd', idx)"
              />
              <van-field
                readonly
                clickable
                autosize
                is-link
                :value="item.proposerName"
                name="proposerName"
                label="申请人"
                placeholder="请选择人员"
                required
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: item.choose,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
                @click="SAWProposerClick(idx)"
              >
              </van-field>
            </van-collapse-item>
          </van-collapse>
          <van-field
            readonly
            clickable
            is-link
            :value="SAWForm.approverName.toString()"
            name="approverList"
            :label="TEXT_WORK_APPROVER"
            placeholder="请选择"
            required
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
            @click="workApprovalPersonClick(TEXT_WORK_APPROVER)"
          />
          <van-field
            readonly
            clickable
            is-link
            :value="SAWForm.supervisorName"
            name="supervisorName"
            :label="TEXT_SAFETY_ANALYSIS_HEAD"
            placeholder="请选择"
            required
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
            @click="workApprovalPersonClick(TEXT_SAFETY_ANALYSIS_HEAD)"
          />
        </van-form>
      </section>
      <div class="confirm-btn-box">
        <van-button
          class="cancel-btn"
          round
          size="small"
          type="info"
          @click="SAWVisibi = false"
          >取 消</van-button
        >
        <van-button
          class="confirm-btn"
          round
          size="small"
          type="info"
          :loading="sameAddLoad"
          @click="sameAddWorkticketConfirm()"
          >确 定</van-button
        >
      </div>
    </van-popup>
    <select-person-by-tabs
      title="申请人(单选)"
      :visible="showProposerPicker"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :multiple="false"
      :is-department-auth="false"
      :choose-value="proposerList"
      @close="showProposerPicker = false"
      @confirm="onProposerConfirm"
    />
    <select-person
      :title="selectPersonTitle"
      :visible="selectPersonVisible"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="personMultipleSwitch"
      department-disabled-key="-"
      :choose-value="choosePersonSelect"
      @close="selectPersonVisible = false"
      @confirm="SAWOnPersonConfirm"
    />
    <select-popup
      v-model="formBase.riskAreaId"
      :data="geoList"
      title="电子围栏"
      :visible="fencePopupVisible"
      label-key="areaName"
      value-key="id"
      @close="fencePopupVisible = false"
      @confirm="onConfirm"
    />
    <select-person
      title="属地负责人(多选)"
      :visible="territorialLeaderVisible"
      :show-department-name="false"
      :show-phone-number="false"
      position="bottom"
      :include-children="true"
      :multiple="true"
      department-disabled-key="-"
      :choose-value="rescuePlanForm.territorialLeaderList"
      @close="territorialLeaderVisible = false"
      @confirm="territorialLeaderChange"
    />
    <van-popup v-model="tipsVisible" class="tips-popup" closeable>
      <div class="tips-popup-title">提示</div>
      <div class="tips-popup-text">
        <span class="tips-text"
          >当前作业区域是重大危险源区域，不允许设置二级动火作业。</span
        >
      </div>
    </van-popup>
    <SelectPopupGrade
      v-model="workTypePersonCur"
      :immediately="false"
      title="人员(多选)"
      :list="workTypePersonOptions[curWorkUnitIdx] || []"
      :visible.sync="workTypePersonVisible"
      popup-height="70%"
      :is-show-confrim-icon="false"
      is-show-footer
      multi
      list-key="personId"
      :props="{
        label: 'personName',
        value: 'personId'
      }"
      @sure="workTypePersonChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import WorkAreaPicker from "@/components/picker/WorkAreaPicker";
import { getdeptInfoByFacilityId } from "@/api/basics/workticket";
import {
  getGeoInfoById,
  getGeoListByPoiId,
  getSelectWorkTypes,
  getJsaAnalysesGovernanceLevel,
  getSelectStepsClassification,
  getWorkDictCommons,
  getWorkDictOptions
} from "@/api/psm";
import {
  getDepartmentByFacilityId,
  getSltContractorsShortName
} from "@/api/base";
import {
  getDeptNameCascadeByDeptCode,
  getSelectTreeDept
} from "@/api/authorization";
import { treeForEach, deepClone, parseTime } from "@/utils/index";
import { getLicenseDict } from "@/api/psm";
import {
  getWorkJsaInfo,
  getRelJsaList,
  getWorkTypeList,
  addWorkJsa,
  updateWorkJsa,
  commitWorkJsa,
  updateCommitWorkJsa,
  signatureWorkJsa,
  signedWorkJsa,
  feedBackWorkJsa,
  getWorkticketList,
  getProtectiveEquipment,
  addProtectiveEquipment,
  deleteProtectiveEquipment,
  batchAddWorkticket,
  judgeDangerfacilities,
  jsaApprovePass,
  jsaApproveRollback,
  jsaApproveRecall,
  getWorkListPersons
} from "@/api/workticket";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import {
  WORKTICKET_TYPE,
  RISK_LEVEL,
  LS_DATA,
  lecLTableData,
  lecETableData,
  lecCTableData,
  evaluationMethodData
} from "@/views/workticket/utils/constant";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import Tag from "@/components/Tag";
import { getJsaInfo } from "@/api/psm/jsa";
import InputDialog from "@/components/dialog/InputDialog";
import CheckboxEditField from "@/components/edit/CheckboxEditField";
import { uniq, uniqBy, compact, filter } from "lodash";
import projectTextConfig from "@/utils/projectTextConfig";
import RadioEditField from "@/components/edit/RadioEditField";
import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import SelectPopup from "@/components/SelectPopup";
import ElevationLevelTypeInfo from "./ElevationLevelTypeInfo.vue";
import MaintenanceHours from "../components/MaintenanceHours";
import ProjectCategory from "../components/ProjectCategory";
import ProjectRiskLevel from "../components/ProjectRiskLevel";
import ProjectLeader from "../components/ProjectLeader";
import SafetyLeader from "../components/SafetyLeader";
import OverhaulLeader from "../components/OverhaulLeader";
import ProjectSource from "../components/ProjectSource";

export default {
  name: "WorkBeforeSafetyAnalyzeEdit",
  components: {
    SelectPopup,
    draggable,
    WorkticketPersonPicker,
    Tag,
    CheckboxEditField,
    RadioEditField,
    FileUploader,
    ElevationLevelTypeInfo,
    MaintenanceHours,
    ProjectCategory,
    ProjectRiskLevel,
    ProjectLeader,
    SafetyLeader,
    OverhaulLeader,
    ProjectSource
  },
  data() {
    return {
      projectTextConfig,
      fencePopupVisible: false,
      geoInfo: {},
      geoList: [],
      // projectName: window.globalConfig.VUE_APP_NAME,
      facilityMap: {
        label: "",
        value: "",
        type: ""
      },
      facilityVisible: false,
      departmentVisible: false,
      workJSAList: [],
      workStepType: "add",
      rejectJsa: false,
      rejectForm: {
        rejectReason: ""
      },
      workStepIndex: -1,
      dynamicRule: true,
      api: getWorkticketList,
      nameVisible: false,

      WORKTICKET_TYPE,
      type: this.$route.params.type || "add",
      id: this.$route.params.id,
      editable: this.$route.params.type === "add",
      formBase: {
        riskAreaId: "",
        riskAreaName: "",
        status: null,
        jsaName: "", // 作业名称
        jsaDescribe: "",
        orgCode: this.$store.state.login.userInfo.orgCode,
        department: "",
        departmentName: "",
        departmentNames: "",
        facilityId: "", // 作业区域id
        facilityType: "", // 作业区域类型
        facilityName: "", // 作业区域名称
        workLocation: "", // 具体作业位置
        planStart: "", // 计划开始时间
        planEnd: "", // 计划结束时间
        analyst: "", // 分析人
        analystDate: "", // 分析时间
        protectiveEquipment: [],
        safetyAnalysis: "", // 安全分析，逗号分隔 1jsa，2施工方案，3救援预案
        openAnalysis: "",
        workPlan: "",
        planFile: [],
        rescuePlan: "",
        rescueFile: [],
        construction: "",
        constructionFile: [],
        drawing: "",
        drawingFile: [],
        other: "",
        otherFile: [],
        workTypeList: [
          // {
          //   contractorStatus: 1, // 是否承包商0：否，1：是
          //   specialJobType: "5", // 作业类型
          //   licenseType: ["2"], // 作业人员所需资质
          //   name: "动火", // 作业类型名称
          //   workTypeId: "", // 作业类型id
          //   deptName: "江苏啦啦啦", // 所属单位名称
          //   deptShortName: "", // 所属单位简称，只有承包商存在
          //   deptCode: "", // 所属单位code
          //   workerList: {
          //     leader: {
          //       id: "",
          //       name: "",
          //       fileId: "",
          //       // 私有
          //       value: "",
          //       label: ""
          //     },
          //     workerList: [
          //       // {
          //       //   id: "",
          //       //   name: "",
          //       //   fileId: "",
          //       //   // 私有
          //       //   value: "",
          //       //   label: ""
          //       // }
          //     ],
          //     guarderList: [],
          //     analyst: {
          //       id: "",
          //       name: "",
          //       fileId: "",
          //       // 私有
          //       value: "",
          //       label: ""
          //     },
          //     hoistingCommandList: [],
          //     hoistingPersonList: [],
          //     productionUnitCommander: {
          //       id: "",
          //       name: "",
          //       fileId: "",
          //       // 私有
          //       value: "",
          //       label: ""
          //     }
          //   }
          // }
        ],
        jsaStepList: [
          // 作业前安全分析表
          // {
          //   step: "", // 工作步骤
          //   jsaDescribe: "", // 危害描述
          //   measures: "", // 控制措施
          //   executorList: [
          //     // 执行人
          //     {
          //       id: "",
          //       name: "", // 作业类型名称
          //       fileId: "" // 签名对应的图片id
          //     }
          //   ]
          // }
        ],
        jsaLeaderList: [
          // {
          //   // jsa组长
          //   id: "",
          //   name: "", // 作业类型名称
          //   fileId: "", // 签名对应的图片id
          //   // 私有
          //   value: "",
          //   label: ""
          // }
        ],
        jsaApproverList: [
          // {
          //   // jsa审批人
          //   id: "",
          //   name: "", // 作业类型名称
          //   fileId: "", // 签名对应的图片id
          //   // 私有
          //   value: "",
          //   label: ""
          // }
        ],
        jsaRelateList: [
          // 分析人员
          // {
          //   id: "",
          //   name: "", // 作业类型名称
          //   fileId: "" // 签名对应的图片id
          // }
        ],
        jsaFeedBack: "", // jsa反馈
        approveStatus: 0, // 是否允许审批（北元）
        recallStatus: 0 // 是否允许退回（北元）
      },
      showWorkTypePicker: false,
      workTypeList: [],
      workTypeColorTrigger: false,
      addWorkTypeActiveNames: [],
      showTimePicker: false,
      initTime: "",
      planTimeCurProp: "",
      workUnitActive: 1,
      showWorkUnitPicker: false,
      contractorsSelectData: [],
      contractorsChooseItem: {},
      contractorFilterItems: [],
      curWorkUnitIdx: 0,
      deptTreeData: [],
      deptSelectValue: {},
      curLicenseTypeIdx: 0,
      showLicensePicker: false,
      licenseDictTreeData: [],
      licenseSelectValue: {},
      licenseSelectValueOld: {},
      safetyAnalyTable: [
        // {
        //   id: 1,
        //   step: "带工具进场",
        //   jsaDescribe: "工具缺损上海、落伍、夹伤工具",
        //   measures: "现场搭建的移动式脚手架必须进行检查确认可靠",
        //   executorList: [
        //     {
        //       name: "王大明",
        //       id: "p1",
        //       fileId: ""
        //     }
        //   ],
        //   choose: false
        // },
        // {
        //   id: 2,
        //   step: "不带工具进场",
        //   jsaDescribe: "工具缺损上海、落伍、夹伤工具",
        //   measures: "现场搭建的移动式脚手架必须进行检查确认可靠",
        //   executorList: [
        //     {
        //       name: "王大明",
        //       id: "p2",
        //       fileId: ""
        //     }
        //   ],
        //   choose: false
        // }
      ],
      addWorkStepPopup: false,
      analyzeTableRowForm: {
        step: "", // 动作步骤
        jsaDescribe: "", // 危害描述
        measures: "", // 控制措施
        executorList: [
          // {
          //   measures: "", // 控制措施（必填）
          //   evaluationMethod: "" // 评价方法：1. LS, 0. LEC（添加第一条记录时可选）
          // }
        ]
      },
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      showGuarderPicker: [],
      showAnalystPicker: [],
      showPUCPicker: [],
      showJSALeaderPicker: false,
      showJsaCheckerPicker: false,
      showOtherPicker: false,
      completeWorkTypeList: [],
      showExecutorPicker: false,
      personConfirmIndex: 0,
      editExecutorVisible: false,
      SAWVisibi: false,
      formType: 0,
      SAWAddWorkTypeActiveNames: [],
      SAWForm: {
        workTypeList: [],
        approverList: [],
        approverName: [],
        supervisorList: [],
        supervisorName: ""
      },
      proposerList: [],
      SAWProposerIdx: 0,
      personalProtectionOptionts: [],
      showProposerPicker: false,
      selectPersonTitle: projectTextConfig.TEXT_WORK_APPROVER,
      selectPersonVisible: false,
      choosePersonSelect: [],
      selectPersonObj: {
        [projectTextConfig.TEXT_WORK_APPROVER]: {
          valueKey: "approverList",
          labelKey: "approverName"
        },
        [projectTextConfig.TEXT_SAFETY_ANALYSIS_HEAD]: {
          valueKey: "supervisorList",
          labelKey: "supervisorName"
        }
      },
      SAWPlanTimeCurPropIdx: 0,
      sameAddLoad: false,
      FILE_ACCEPT_TYPES,
      dateErrorMessage: "",
      LS_DATA,
      RISK_LEVEL,
      lecLTableData,
      lecETableData,
      lecCTableData,
      evaluationMethodData,
      levelData: [],
      classificationData: [],
      tableEvaluationMethod: "1",
      inherentLsLVisiable: false,
      inherentLsSVisiable: false,
      inherentLecLVisiable: false,
      inherentLecEVisiable: false,
      inherentLecCVisiable: false,
      inherentRiskLevelCodeVisiable: false,
      inherentGovernanceLevelVisiable: false,
      lsLVisiable: false,
      lsSVisiable: false,
      lecLVisiable: false,
      lecEVisiable: false,
      lecCVisiable: false,
      riskLevelCodeVisiable: false,
      governanceLevelVisiable: false,
      isRH: window.globalConfig.VUE_APP_WORK_RH === "RH",
      rescuePlanVisible: false,
      rescuePlanOrigin: [],
      rescuePlanForm: {
        plan: [],
        planIds: [],
        measureInner: [],
        measureOuter: [],
        measureOther: [],
        device: [],
        deviceIds: [],
        territorialLeaderList: [],
        dictIdCounter: 0
      },
      territorialLeaderVisible: false,
      hoistingCommandListVisible: false,
      hoistingPersonListVisible: false,
      submitPerson:
        window.globalConfig.VUE_APP_WORK_JSA_SUBMIT_PERSON === "jsaLeader",
      filterString: "",
      flatTreeData: [],
      keyword: "",
      tipsVisible: false,
      disabledWorkTypes: [],
      isDangerfacilities: false,
      normalPersonPickerVisible: false,
      workerPickerVisible: false,
      workTypePersonVisible: false,
      workTypePersonOptions: [],
      workTypePersonCur: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    personMultipleSwitch() {
      return this.selectPersonTitle === this.TEXT_WORK_APPROVER;
    },
    workTypeListValueSwitch() {
      // 值只作为是否填写的校验作用
      return this.formBase.workTypeList.length ? " " : "";
    },
    workTypeColorSwitch() {
      if (this.workTypeColorTrigger && !this.formBase.workTypeList.length) {
        return "#ee0a24";
      }
      return "#ccc";
    },
    signatureSwitch() {
      return [1, 2, 3].includes(this.formBase.status);
    },
    signatureVerify() {
      // const step = this.safetyAnalyTable.some(i =>
      //   i.executorList.some(e => !e.fileId)
      // );
      // const wtl = this.formBase.workTypeList.some((i, idx) => {
      //   const wt = this.completeWorkTypeList[idx];
      //   if (!wt) return true;
      //   const sjp = i.workerList;
      //   const leader = !sjp.leader.fileId;
      //   const workerList =
      //     wt.specialJobType !== "5"
      //       ? sjp.workerList.some(p => !p.fileId)
      //       : false;
      //   const ordinaryWorkerList =
      //     wt.specialJobType !== "5"
      //       ? sjp.ordinaryWorkerList.some(p => !p.fileId)
      //       : false;
      //   const hoistingCommandList =
      //     wt.specialJobType === "5"
      //       ? sjp.hoistingCommandList.some(p => !p.fileId)
      //       : false;
      //   const hoistingPersonList =
      //     wt.specialJobType === "5"
      //       ? sjp.hoistingPersonList.some(p => !p.fileId)
      //       : false;
      //   const guarderList = sjp.guarderList.some(p => !p.fileId);
      //   const analyst = sjp.analyst.id && !sjp.analyst.fileId ? true : false;
      //   const productionUnitCommander =
      //     wt.specialJobType === "4" &&
      //     sjp.productionUnitCommander.id &&
      //     !sjp.productionUnitCommander.fileId
      //       ? true
      //       : false;
      //   return (
      //     leader ||
      //     workerList ||
      //     ordinaryWorkerList ||
      //     hoistingCommandList ||
      //     hoistingPersonList ||
      //     guarderList ||
      //     analyst ||
      //     productionUnitCommander
      //   );
      // });
      // const jsaLeader = !this.formBase.jsaLeader.fileId;
      // const jsaApprover = !this.formBase.jsaApprover.fileId;
      // const jsaRelate = this.formBase.jsaRelate.some(p => !p.fileId);
      // return step || wtl || jsaLeader || jsaApprover || jsaRelate;
      const jsaLeader = !this.formBase.jsaLeaderList.some(p => p.fileId);
      // 审核人多选 或签
      const jsaApprover = !this.formBase.jsaApproverList.some(p => p.fileId);
      return jsaLeader || jsaApprover;
    },
    checkoutUser() {
      const userId = this.userInfo.id;

      const step = (this.safetyAnalyTable || []).some(i =>
        i.executorList.some(e => userId == e.id)
      );
      const wtl = this.formBase.workTypeList.some((i, idx) => {
        const sjp = i.workerList;
        const leader = userId == sjp.leader.id;
        const workerList = sjp.workerList.some(p => userId == p.id);
        const hoistingCommandList = sjp.hoistingCommandList.some(
          p => userId == p.id
        );
        const hoistingPersonList = sjp.hoistingPersonList.some(
          p => userId == p.id
        );
        const guarderList = sjp.guarderList.some(p => userId == p.id);
        const analyst = sjp.analyst.id && userId == sjp.analyst.id;
        const productionUnitCommander =
          userId == sjp.productionUnitCommander.id;

        return (
          leader ||
          workerList ||
          hoistingCommandList ||
          hoistingPersonList ||
          guarderList ||
          analyst ||
          productionUnitCommander
        );
      });
      const analyst2 = userId == this.formBase.analyst.id;
      const jsaLeader = this.formBase.jsaLeaderList.some(p => userId == p.id);
      const jsaApprover = this.formBase.jsaApproverList.some(
        p => userId == p.id
      );
      const jsaRelate = this.formBase.jsaRelateList.some(p => userId == p.id);
      return step || wtl || jsaLeader || jsaApprover || jsaRelate || analyst2;
    },
    navBarTitleSwitch() {
      if (this.type === "add") return "新建作业活动管理";
      const obj = {
        0: "待提交",
        1: "待签名",
        2: "待反馈",
        3: "已完成",
        4: "已删除",
        5: "待审批"
      };
      const suffix = obj[this.formBase.status] || "";
      return `作业活动管理(${suffix})`;
    },
    editBtnAuth() {
      const personIds = [
        this.formBase.cuserId,
        this.formBase.jsaLeaderList.map(i => i.id),
        this.formBase.jsaApproverList.map(i => i.id)
      ];
      return personIds.includes(this.userInfo.id);
    },
    editExecutorSwitch() {
      if (!this.analyzeTableRowForm.executorList.length) return "";
      return this.analyzeTableRowForm.executorList.map(i => i.name).toString();
    }
    // workTypeListSwitch() {
    //   return this.workTypeList.filter(
    //     i =>
    //       !this.formBase.workTypeList.find(w => w.workTypeId === i.workTypeId)
    //   );
    // }
  },
  watch: {
    "analyzeTableRowForm.lsL": {
      handler: function(val) {
        if (val && this.analyzeTableRowForm.lsS) {
          this.analyzeTableRowForm.lsR =
            parseInt(val) * parseInt(this.analyzeTableRowForm.lsS);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.lsS": {
      handler: function(val) {
        if (val && this.analyzeTableRowForm.lsL) {
          this.analyzeTableRowForm.lsR =
            parseInt(val) * parseInt(this.analyzeTableRowForm.lsL);
        }
      },
      immediate: true,
      deep: true
    },
    "analyzeTableRowForm.lecL": {
      handler: function(val) {
        if (
          val &&
          this.analyzeTableRowForm.lecE &&
          this.analyzeTableRowForm.lecC
        ) {
          this.analyzeTableRowForm.lecD =
            parseFloat(val) *
            parseFloat(this.analyzeTableRowForm.lecE) *
            parseFloat(this.analyzeTableRowForm.lecC);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.lecE": {
      handler: function(val) {
        if (
          val &&
          this.analyzeTableRowForm.lecL &&
          this.analyzeTableRowForm.lecC
        ) {
          this.analyzeTableRowForm.lecD =
            parseFloat(val) *
            parseFloat(this.analyzeTableRowForm.lecL) *
            parseFloat(this.analyzeTableRowForm.lecC);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.lecC": {
      handler: function(val) {
        if (
          val &&
          this.analyzeTableRowForm.lecE &&
          this.analyzeTableRowForm.lecL
        ) {
          this.analyzeTableRowForm.lecD =
            parseFloat(val) *
            parseFloat(this.analyzeTableRowForm.lecL) *
            parseFloat(this.analyzeTableRowForm.lecE);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.inherentLsL": {
      handler: function(val) {
        if (val && this.analyzeTableRowForm.inherentLsS) {
          this.analyzeTableRowForm.inherentLsR =
            parseInt(val) * parseInt(this.analyzeTableRowForm.inherentLsS);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.inherentLsS": {
      handler: function(val) {
        if (val && this.analyzeTableRowForm.inherentLsL) {
          this.analyzeTableRowForm.inherentLsR =
            parseInt(val) * parseInt(this.analyzeTableRowForm.inherentLsL);
        }
      },
      immediate: true,
      deep: true
    },
    "analyzeTableRowForm.inherentLecL": {
      handler: function(val) {
        if (
          val &&
          this.analyzeTableRowForm.inherentLecE &&
          this.analyzeTableRowForm.inherentLecC
        ) {
          this.analyzeTableRowForm.inherentLecD =
            parseFloat(val) *
            parseFloat(this.analyzeTableRowForm.inherentLecE) *
            parseFloat(this.analyzeTableRowForm.inherentLecC);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.inherentLecE": {
      handler: function(val) {
        if (
          val &&
          this.analyzeTableRowForm.inherentLecL &&
          this.analyzeTableRowForm.inherentLecC
        ) {
          this.analyzeTableRowForm.inherentLecD =
            parseFloat(val) *
            parseFloat(this.analyzeTableRowForm.inherentLecL) *
            parseFloat(this.analyzeTableRowForm.inherentLecC);
        }
      },
      deep: true,
      immediate: true
    },
    "analyzeTableRowForm.inherentLecC": {
      handler: function(val) {
        if (
          val &&
          this.analyzeTableRowForm.inherentLecE &&
          this.analyzeTableRowForm.inherentLecL
        ) {
          this.analyzeTableRowForm.inherentLecD =
            parseFloat(val) *
            parseFloat(this.analyzeTableRowForm.inherentLecL) *
            parseFloat(this.analyzeTableRowForm.inherentLecE);
        }
      },
      deep: true,
      immediate: true
    },
    safetyAnalyTable: {
      handler: function(val) {
        this.tableEvaluationMethod =
          val && val.length ? String(val[0].evaluationMethod) : "1";
      },
      deep: true,
      immediate: true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.pageInit();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    pageInit() {
      this.getJsaAnalysesGovernanceLevel();
      this.getSelectStepsClassification();
      this.getProtectiveEquipment();
      this.getWorkDict(this.id);
      if (this.type !== "add" && this.id) {
        this.getWorkJsaInfo();
      }
      if (this.type === "add") {
        this.formBase.department = this.userInfo.department;
        this.formBase.departmentName = this.userInfo.departmentName;
        this.formBase.departmentNames = this.userInfo.departmentName;
        this.templateInit();
      }
    },
    onConfirm(row) {
      this.formBase.riskAreaName = row.areaName;
    },
    async getGeo(id) {
      if (!id) return;
      this.geoList = await getGeoListByPoiId(id);
      this.formBase.riskAreaId = this.geoList[0]?.id || "";
      this.formBase.riskAreaName = this.geoList[0]?.areaName || "";
    },
    async getGeoInfo(id) {
      if (!id) return;
      this.geoInfo = await getGeoInfoById(id);
    },
    sameAddWorkticketConfirm() {
      const workticket = this.SAWForm.workTypeList.filter(i => i.choose);
      if (!workticket.length) {
        this.$toast.fail("请选择作业票");
        return;
      }
      this.$refs.sameAddWorkticketForm.validate().then(async res => {
        this.sameAddLoad = true;
        const {
          id,
          jsaName,
          department,
          facilityId,
          facilityType,
          facilityName,
          workLocation
        } = this.formBase;
        const {
          supervisorList,
          supervisorName,
          approverList,
          approverName
        } = this.SAWForm;
        const params = workticket.reduce((acc, cur) => {
          const obj = {
            ...this.formBase,
            workJsaId: id,
            name: cur.jsaName,
            orgCode: this.userInfo.orgCode,
            department,
            facilityId,
            facilityType,
            facilityName,
            location: workLocation,
            approverList,
            supervisorList,
            planStart: cur.planStart,
            planEnd: cur.planEnd,
            proposerList: cur.proposerList,
            proposerName: cur.proposerName,
            workTypeList: [
              {
                ...cur,
                id: cur.workTypeId,
                licenseType: cur.licenseType ? [cur.licenseType] : [],
                licenseTypeName: cur.licenseTypeName || [],
                hasRescue: this.isRH
              }
            ]
          };
          acc.push(obj);
          return acc;
        }, []);
        try {
          await batchAddWorkticket({
            ticketList: params
          });
        } finally {
          this.sameAddLoad = false;
        }
        this.$toast.fail("建票成功");
        this.SAWVisibi = false;
      });
    },
    SAWOnPersonConfirm(ids, list) {
      const keyObj = this.selectPersonObj[this.selectPersonTitle];
      // 默认多选数据
      // let value = ids;
      // let label = list.map(item => item.label);
      // if (this.selectPersonTitle === this.TEXT_SAFETY_ANALYSIS_HEAD) {
      //   value = ids[0] || "";
      //   label = label[0] || "";
      // }
      // this.SAWForm[keyObj.valueKey] = value;
      // this.SAWForm[keyObj.labelKey] = label;
      this.SAWForm[keyObj.valueKey] = list.map(i => {
        i.id = i.value;
        i.name = i.label;
        i.type = "0";
        return i;
      });
      const label = list.map(i => i.label);
      this.SAWForm[keyObj.labelKey] =
        this.selectPersonTitle === this.TEXT_SAFETY_ANALYSIS_HEAD
          ? label.toString() || ""
          : label;
    },
    workApprovalPersonClick(title) {
      this.selectPersonTitle = title;
      const keyObj = this.selectPersonObj[title];
      let value = this.SAWForm[keyObj.valueKey];
      // let label = this.SAWForm[keyObj.labelKey];
      // if (typeof value === "string") {
      //   // 转换为多选数据统一处理
      //   value = [value];
      //   label = [label];
      // }
      // this.choosePersonSelect = (value || []).map((item, idx) => {
      //   return {
      //     value: item,
      //     label: label[idx] || item
      //   };
      // });
      this.choosePersonSelect = (value || []).map((item, idx) => {
        return {
          value: item.id,
          label: item.name
        };
      });
      this.selectPersonVisible = true;
    },
    SAWProposerClick(idx) {
      const cur = this.SAWForm.workTypeList[idx];
      this.SAWProposerIdx = idx;
      // this.proposerList = cur.proposer
      //   ? [
      //       {
      //         id: cur.proposer,
      //         name: cur.proposerName,
      //         label: cur.proposerName,
      //         value: cur.proposer
      //       }
      //     ]
      //   : [];
      this.proposerList = cur.proposerList.map(i => i);
      this.showProposerPicker = true;
    },
    onProposerConfirm(ids, list) {
      // this.proposerList = list;
      // this.SAWForm.workTypeList[this.SAWProposerIdx].proposer = ids?.[0] || "";
      // this.SAWForm.workTypeList[this.SAWProposerIdx].proposerName =
      //   list?.[0]?.label || "";
      this.SAWForm.workTypeList[this.SAWProposerIdx].proposerName =
        list?.[0]?.label || "";
      this.SAWForm.workTypeList[this.SAWProposerIdx].proposerList = list.map(
        i => {
          i.id = i.value;
          i.name = i.label;
          i.type = i._mechanismType;
          return i;
        }
      );
    },
    async getProtectiveEquipment() {
      const params = {
        orgCode: this.userInfo.orgCode
      };
      this.personalProtectionOptionts = await getProtectiveEquipment(params);
    },
    ppOptionAdd() {
      this.InputDialogFun(
        "添加个人防护用品",
        () =>
          new Promise(rlv => {
            rlv();
          }),
        [],
        (res, arg) => {
          addProtectiveEquipment({
            name: arg[0]
          })
            .then(res => {
              if (res) {
                this.personalProtectionOptionts.push({
                  id: res,
                  name: arg[0],
                  delete: true
                });
                this.$toast("添加成功");
                return;
              }
              this.$toast("添加失败, 请重试");
            })
            .catch(e => {
              this.$toast("添加失败, 请重试");
            });
        },
        false,
        null,
        null,
        true,
        true,
        32
      );
    },
    async ppOptionDelete(id, scope) {
      await deleteProtectiveEquipment(id);
      this.$toast("删除成功");
      const idx = this.personalProtectionOptionts.findIndex(i => i.id === id);
      this.personalProtectionOptionts.splice(idx, 1);
      this.formBase.protectiveEquipment = this.formBase.protectiveEquipment.filter(
        i => i !== id
      );
    },
    onTreeConfirm(cur, { tree }) {
      let parent = null;
      treeForEach(tree, node => {
        if (node.id === cur._parendId) parent = node;
      });
      this.formBase.departmentName = cur.label;
      this.formBase.departmentNames = parent
        ? parent.label + "-" + cur.label
        : cur.label;
      this.facilityMap.label = this.formBase.facilityName = "";
      this.facilityMap.type = this.formBase.facilityType = "";
      this.facilityMap.value = this.formBase.facilityId = "";
    },
    async onFacilityConfirm(facility) {
      this.formBase.riskAreaName = "";
      this.formBase.riskAreaId = "";
      this.formBase.facilityName = facility.label;
      this.formBase.facilityType = facility.type;
      this.formBase.facilityId = facility.value;
      if (!facility || !Object.keys(facility).length || !facility.value) {
        // this.info.department = "";
        // this.info.departmentName = "";
        this.isDangerfacilities = false;
        return false;
      }
      try {
        this.getGeo(facility.value);
        const ret = await getDepartmentByFacilityId(facility.value);
        this.formBase.department = ret.department;
        this.formBase.departmentName = ret.departmentName;
        // 当前作业区域是重大危险源区域，不允许设置二级动火作业
        this.isDangerfacilities = await judgeDangerfacilities({
          facilitiesId: facility.value
        });
        if (this.isDangerfacilities) {
          // 清除已选的二级动火类别
          this.formBase.workTypeList = this.formBase.workTypeList.filter(
            item => {
              const find = this.workTypeList.find(
                option => option.workTypeId === item.workTypeId
              );
              return find.fireLevel !== 3;
            }
          );
        }
      } catch (e) {
        console.log("onFacilityChange -> e", e);
      }
    },
    workTicketRouterLink(id) {
      this.$router.push({
        name: "worktickeDetail",
        params: { id }
      });
    },
    // 编辑作业安全分析表
    editSafetyAnalyTable(item, key) {
      this.workStepType = "edit";
      this.analyzeTableRowForm = {
        ...this.analyzeTableRowForm,
        ...item,
        step: item.step, // 工作步骤
        jsaDescribe: item.jsaDescribe, // 危害描述
        measures: item.measures, // 控制措施
        // 执行人
        executorList: (item.executorList || []).map(i => {
          i.label = i.name;
          i.value = i.id;
          return i;
        }),
        choose: false,
        acceptStatus: String(item.acceptStatus),
        evaluationMethod: this.tableEvaluationMethod
      };
      this.addWorkStepPopup = true;
      this.workStepIndex = key;
    },
    // 删除作业安全分析表
    deleteSafetyAnalyTable(key) {
      this.safetyAnalyTable.splice(key, 1);
    },
    onNameConfirm(name) {
      this.formBase.jsaName = name;
    },
    async getWorkJsaInfo() {
      const info = await getWorkJsaInfo(this.id);
      this.rescuePlanForm.territorialLeaderList = deepClone(
        info.territorialLeaderList
      ).map(item => ({
        ...item,
        label: item.name,
        value: item.id
      }));
      this.rescuePlanVisible = info.workTypeList.some(
        item => item.specialJobType === "2"
      );
      this.workJSAList = await getRelJsaList({
        workJsaId: this.id,
        orgCode: this.userInfo.orgCode
      });
      this.getGeo(info.facilityId);
      this.getGeoInfo(info.riskAreaId);
      this.completeWorkTypeList = [];
      this.formBase.workTypeList = [];
      const personKeySwitch = p => {
        return {
          ...p,
          value: p.id,
          label: p.name
        };
      };
      info.workTypeList = info.workTypeList.map(w => {
        w.licenseType ? [w.licenseType] : [];
        const wl = w.workerList || [];
        const sjp = {
          leader: wl.filter(i => i.personType === "1")?.[0] || {},
          analyst: wl.filter(i => i.personType === "5")?.[0] || {},
          productionUnitCommander:
            wl.filter(i => i.personType === "13")?.[0] || {},
          workerList: wl.filter(i => i.personType === "3"),
          ordinaryWorkerList: wl.filter(i => i.personType === "15"),
          guarderList: wl.filter(i => i.personType === "2"),
          hoistingCommandList: wl.filter(i => i.personType === "11"),
          hoistingPersonList: wl.filter(i => i.personType === "12")
        };
        sjp.leader = {
          ...sjp.leader,
          value: sjp.leader.id,
          label: sjp.leader.name
        };
        sjp.analyst = {
          ...sjp.analyst,
          value: sjp.analyst.id,
          label: sjp.analyst.name
        };
        sjp.productionUnitCommander = {
          ...sjp.productionUnitCommander,
          value: sjp.productionUnitCommander.id,
          label: sjp.productionUnitCommander.name
        };
        sjp.workerList = sjp.workerList.map(personKeySwitch);
        sjp.ordinaryWorkerList = (sjp.ordinaryWorkerList || []).map(
          personKeySwitch
        );
        sjp.guarderList = sjp.guarderList.map(personKeySwitch);
        sjp.hoistingCommandList = sjp.hoistingCommandList.map(personKeySwitch);
        sjp.hoistingPersonList = sjp.hoistingPersonList.map(personKeySwitch);
        w.workTypePerson = wl
          .filter(i => i.personType === "28")
          .map(item => {
            item.personId = item.id;
            item.personName = item.name;
            return item;
          });
        return {
          ...w,
          workerList: sjp
        };
      });
      info.jsaLeaderList = (info.jsaLeaderList || []).map(i => {
        return {
          ...i,
          value: i.id,
          label: i.name,
          personType: "17"
        };
      });
      // info.jsaApprover = {
      //   ...info.jsaApprover,
      //   value: info.jsaApprover.id,
      //   label: info.jsaApprover.name
      // };
      info.jsaApproverList = (info.jsaApproverList || []).map(i => {
        return {
          ...i,
          value: i.id,
          label: i.name,
          personType: "6"
        };
      });
      info.jsaRelateList = (info.jsaRelateList || []).map(i => {
        return {
          ...i,
          value: i.id,
          label: i.name,
          personType: "7"
        };
      });
      info.planStart = info.planStart || "";
      info.planEnd = info.planEnd || "";
      info.departmentNames = await this.getDeptParent(info.department);
      info.protectiveEquipment = info.protectiveEquipment
        ? info.protectiveEquipment.split(",")
        : [];
      info.safetyAnalysis = info.safetyAnalysis || "";
      info.openAnalysis = info.safetyAnalysis.includes("1") ? 1 : 0;
      info.workPlan = info.safetyAnalysis.includes("2") ? 1 : 0;
      info.planFile = info.planFile || [];
      info.rescuePlan = info.safetyAnalysis.includes("3") ? 1 : 0;
      info.rescueFile = info.rescueFile || [];
      info.construction = info.safetyAnalysis.includes("4") ? 1 : 0;
      info.constructionFile = info.constructionFile || [];
      info.drawing = info.safetyAnalysis.includes("5") ? 1 : 0;
      info.drawingFile = info.drawingFile || [];
      info.other = info.safetyAnalysis.includes("6") ? 1 : 0;
      info.otherFile = info.otherFile || [];
      this.formBase = info;
      this.facilityMap.label = info.facilityName;
      this.facilityMap.value = info.facilityId;
      this.facilityMap.type = info.facilityType;
      try {
        const params = {
          workTypeSpecialList: info.workTypeList.map(i => {
            i.licenseType = Array.isArray(i.licenseType)
              ? i.licenseType[0] || ""
              : i.licenseType || "";
            return i;
          })
        };
        const arr = await getWorkTypeList(params);
        arr.forEach(i => {
          i.licenseType = i.licenseType || [];
        });
        const len = arr.length;
        this.showGuarderPicker = Array(len).fill(false);
        this.showAnalystPicker = Array(len).fill(false);
        this.showPUCPicker = Array(len).fill(false);
        this.completeWorkTypeList.push(...arr);
      } catch (e) {
        this.$toast.fail(`${projectTextConfig.TEXT_WORK_DEPT}数据请求失败`);
      }
      this.safetyAnalyTable = info.jsaStepList.map(i => {
        i.showSubItem = false;
        i.executorList = (i.executorList || []).map(e => {
          e.choose = false;
          return e;
        });
        return i;
      });
      this.editable = info.status === 0 && info.cuserId === this.userInfo.id;
      if (!this.editable && this.rescuePlanForm.measureOuter.length) {
        this.rescuePlanForm.measureOuter = this.rescuePlanForm.measureOuter.filter(
          item => item.dictStatus
        );
      }
      if (!this.editable && this.rescuePlanForm.measureInner.length) {
        this.rescuePlanForm.measureInner = this.rescuePlanForm.measureInner.filter(
          item => item.dictStatus
        );
      }
      if (info.facilityId) {
        this.isDangerfacilities = await judgeDangerfacilities({
          facilitiesId: info.facilityId
        });
      }
    },
    processDepartmentNames(data = []) {
      return data.slice(-2).join("-");
    },
    async getDeptParent(code) {
      const data = await getDeptNameCascadeByDeptCode(code);
      return this.processDepartmentNames(data);
    },
    async workAreaChange(id, label, allDataList) {
      if (!id) {
        this.formBase.facilityId = "";
        this.formBase.facilityName = "";
        this.formBase.facilityType = "";
        this.formBase.departmentName = "";
        this.formBase.department = "";
        return;
      }
      this.formBase.facilityName = label;
      this.formBase.facilityType = allDataList[0] ? allDataList[0].type : "";
      const params = {
        facilitiesId: id
      };
      const deptInfo = await getdeptInfoByFacilityId(params);
      this.formBase.departmentName = deptInfo.departmentName;
      this.formBase.department = deptInfo.department;
    },
    SAWPlanEndValidator(val, idx) {
      if (!val || !this.SAWForm.workTypeList[idx].planStart) return true;
      const endTime = Date.parse(new Date(val));
      const startTime = Date.parse(
        new Date(this.SAWForm.workTypeList[idx].planStart)
      );
      return endTime > startTime;
    },
    SAWPlanStartValidator(val, idx) {
      if (!val || !this.SAWForm.workTypeList[idx].planEnd) return true;
      const startTime = Date.parse(new Date(val));
      const endTime = Date.parse(
        new Date(this.SAWForm.workTypeList[idx].planEnd)
      );
      return endTime > startTime;
    },
    planEndValidator(val) {
      if (!val || !this.formBase.planStart) return true;
      const endTime = Date.parse(new Date(val));
      const startTime = Date.parse(new Date(this.formBase.planStart));
      return endTime > startTime;
    },
    planStartValidator(val) {
      if (!val || !this.formBase.planEnd) return true;
      const startTime = Date.parse(new Date(val));
      const endTime = Date.parse(new Date(this.formBase.planEnd));
      return endTime > startTime;
    },
    // planDateTimeoutValidator(val) {
    //   const start = this.formBase.planStart;
    //   const end = this.formBase.planEnd;
    //   const findTimesList = this.formBase.workTypeList.filter(
    //     item => item.times
    //   );
    //   if (!start || !end || !findTimesList.length) return true;
    //   const obj = {
    //     h: 3600000,
    //     d: 86400000
    //   };
    //   const millisecondList = findTimesList
    //     .map(item => Number(item.times) * obj[item.timesUnit])
    //     .sort((a, b) => b - a);
    //   const planTime = Date.parse(end) - Date.parse(start);
    //   return planTime <= millisecondList[0];
    // },
    planDateTimeoutValidator(val, item) {
      const start = item.planStart;
      const end = item.planEnd;

      this.dateErrorMessage = `计划时间超出作业活动时间范围（${this.formBase.planStart}-${this.formBase.planEnd}）`;

      if (Date.parse(val) < Date.parse(this.formBase.planStart)) {
        return false;
      }
      if (Date.parse(val) > Date.parse(this.formBase.planEnd)) {
        return false;
      }

      const find = this.completeWorkTypeList.find(
        i => item.workTypeId === i.id
      );
      if (!start || !end || !find || !find.times) return true;
      const planDateUnit =
        "(" + find.times + (find.timesUnit === "d" ? "天)" : "小时)");

      this.dateErrorMessage = `计划时间超出作业许可时限${planDateUnit}`;
      const obj = {
        h: 3600000,
        d: 86400000
      };
      const timeLimit = Number(find.times) * obj[find.timesUnit];
      const planTime = Date.parse(end) - Date.parse(start);
      return planTime <= timeLimit;
    },
    planTimeClick(prop) {
      if (!this.editable) return;
      this.planTimeCurProp = prop;
      this.formType = 1;
      const datetime = this.formBase[prop];
      this.initTime = datetime ? new Date(datetime) : new Date();
      this.showTimePicker = true;
    },
    SAWPlanTimeClick(prop, idx) {
      this.planTimeCurProp = prop;
      this.SAWPlanTimeCurPropIdx = idx;
      this.formType = 2;
      const datetime = this.SAWForm.workTypeList[idx][prop];
      this.initTime = datetime ? new Date(datetime) : new Date();
      this.showTimePicker = true;
    },
    onDateimeConfirm(time) {
      const res = parseTime(time, "{y}-{m}-{d} {h}:{i}:00");
      if (this.formType === 1) {
        this.formBase[this.planTimeCurProp] = res;
      } else if (this.formType === 2) {
        this.SAWForm.workTypeList[this.SAWPlanTimeCurPropIdx][
          this.planTimeCurProp
        ] = res;
      }
      this.showTimePicker = false;
    },
    async workTypeClick() {
      if (!this.editable) return;
      const res = await getSelectWorkTypes(this.userInfo.orgCode);
      const arr = Array.isArray(res) ? res : [];
      const list = (arr || []).map(item => {
        return {
          specialJobType: item.specialJobType, // 作业类型
          licenseType: [], // 作业人员所需资质
          licenseTypeName: [],
          name: item.name, // 作业类型名称
          workTypeId: item.id, // 作业类型id
          deptName: "", // 所属单位名称
          deptShortName: "", // 所属单位简称，承包商有
          deptCode: "", // 所属单位code
          times: item.times, // 作业时限
          timesUnit: item.timesUnit,
          fireLevel: item.fireLevel,
          workerList: {
            leader: {
              id: "",
              name: "",
              fileId: "",
              // 私有
              value: "",
              label: "",
              personType: "1"
            },
            workerList: [],
            ordinaryWorkerList: [],
            guarderList: [],
            analyst: {
              id: "",
              name: "",
              fileId: "",
              // 私有
              value: "",
              label: "",
              personType: "5"
            },
            hoistingCommandList: [],
            hoistingPersonList: [],
            productionUnitCommander: {
              id: "",
              name: "",
              fileId: "",
              // 私有
              value: "",
              label: "",
              personType: "13"
            }
          },
          choose: false,
          workTypePerson: []
        };
      });
      this.workTypeList = list.filter(
        i =>
          !this.formBase.workTypeList.find(
            w =>
              `${w.workTypeId}-${w.deptCode}` ===
              `${i.workTypeId}-${i.deptCode}`
          )
      );
      this.workTypeColorTrigger = false;
      this.addWorkTypeActiveNames = [];
      this.showWorkTypePicker = true;
      this.disabledWorkTypes = arr
        .filter(item => item.fireLevel === 3)
        .map(item => item.id);
    },
    workTypeListDel(idx) {
      this.completeWorkTypeList.splice(idx, 1);
      this.formBase.workTypeList.splice(idx, 1);
      this.showGuarderPicker.splice(idx, 1);
      this.showAnalystPicker.splice(idx, 1);
      this.showPUCPicker.splice(idx, 1);
      this.rescuePlanStateChange();
    },
    workTypePickerClose() {
      this.workTypeColorTrigger = true;
      this.showWorkTypePicker = false;
    },
    closeIconClick() {
      this.showWorkTypePicker = false;
    },
    workTypeCheckboxChange(val, idx) {
      if (val) {
        if (!this.addWorkTypeActiveNames.includes(idx)) {
          this.addWorkTypeActiveNames.push(idx);
        }
        return;
      }
    },
    SAWWorkTypeCheckboxChange(val, idx) {
      if (val) {
        if (!this.SAWAddWorkTypeActiveNames.includes(idx)) {
          this.SAWAddWorkTypeActiveNames.push(idx);
        }
        return;
      }
    },
    workUnitClick(rowData, idx) {
      this.curWorkUnitIdx = idx;
      this.filterString = "";
      this.keyword = "";
      this.showWorkUnitPicker = true;
      this.workUnitActive = rowData.contractorStatus;
      this.contractorsChooseItem = {};
      this.deptSelectValue = {};
      const obj = {
        label: rowData.deptName,
        value: rowData.deptCode,
        id: rowData.deptCode,
        deptShortName: rowData.deptShortName || ""
      };
      const propKey = {
        "1": "contractorsChooseItem",
        "0": "deptSelectValue"
      };
      const prop = propKey[this.workUnitActive];
      this[prop] = obj;
      this.getDepartmentTreeData();
      this.getContractorsSelectData();
    },
    async getContractorsSelectData() {
      const params = {
        orgCodeInList: this.userInfo.orgCode,
        state: 0
      };
      const data = await getSltContractorsShortName(params);

      // 当前作业已存在企业部门筛除
      const activeData = this.workTypeList[this.curWorkUnitIdx] || {};
      const activeWorkTypeId = activeData.workTypeId;
      const selectedDeptCode = this.formBase.workTypeList
        .filter(i => i.workTypeId === activeWorkTypeId)
        .map(i => i.deptCode);

      this.contractorsSelectData = data.filter(
        item => !selectedDeptCode.includes(item.value)
      );
      this.contractorFilterItems = this.contractorsSelectData;
    },
    async getDepartmentTreeData() {
      try {
        // 当前作业已存在企业部门禁止选择
        const activeData = this.workTypeList[this.curWorkUnitIdx] || {};
        const activeWorkTypeId = activeData.workTypeId;
        const selectedDeptCode = this.formBase.workTypeList
          .filter(i => i.workTypeId === activeWorkTypeId)
          .map(i => i.deptCode);

        const arr = await getSelectTreeDept(this.userInfo.orgCode);
        treeForEach(arr, item => {
          item.filtered = true;
          item.value = item.id;
          item.disabled = selectedDeptCode.includes(item.id);
        });
        this.deptTreeData = arr;
        this.flatTreeData = this.flatTree();
        this.initFilterTree();
        if (this.filterString) {
          this.filterTree(this.filterString);
        }
      } catch (e) {
        console.log("getDepartmentTreeData -> e", e);
      }
    },
    workUnitConfirm() {
      const idx = this.curWorkUnitIdx;
      const propKey = {
        "1": "contractorsChooseItem",
        "0": "deptSelectValue"
      };
      const contractorStatus = this.contractorsChooseItem.value ? "1" : "0";
      const prop = propKey[contractorStatus];
      this.workTypeList[idx].deptName = this[prop].label;
      this.workTypeList[idx].deptCode = this[prop].value;
      this.workTypeList[idx].contractorStatus = contractorStatus;
      this.workTypeList[idx].deptShortName = this.contractorsChooseItem.value
        ? this[prop].shortName
        : "";
      this.showWorkUnitPicker = false;
      if (this.isBy) {
        this.workTypeList[idx].workTypePerson = [];
        this.getWorkListPersons(contractorStatus, this[prop].value);
      }
    },
    contractorsChooseClick(rowData) {
      this.contractorsChooseItem = rowData;
      this.deptSelectValue = {};
    },
    deptTreeInput(item) {
      this.contractorsChooseItem = {};
    },
    licenseTypeClick(rowData, idx) {
      if (rowData.specialJobType === "5") return;
      this.curLicenseTypeIdx = idx;
      this.showLicensePicker = true;
      this.licenseSelectValue = {
        name: rowData.licenseTypeName[0] || "",
        id: rowData.licenseType[0] || ""
      };
      this.licenseSelectValueOld = this.licenseSelectValue.id
        ? { ...this.licenseSelectValue }
        : {};
      this.getLicenseDict();
    },
    async getLicenseDict() {
      const params = {
        orgCode: this.$store.state.login.userInfo.orgCode
      };
      const arr = await getLicenseDict(params);
      treeForEach(arr, item => {
        item.filtered = true;
        item.value = item.id;
        item.choosable = item.children ? false : true;
      });
      this.licenseDictTreeData = arr;
    },
    licenseTypeConfirm() {
      const idx = this.curLicenseTypeIdx;
      this.workTypeList[idx].licenseType = this.licenseSelectValue.id
        ? [this.licenseSelectValue.id]
        : [];
      this.workTypeList[idx].licenseTypeName = this.licenseSelectValue.name
        ? [this.licenseSelectValue.name]
        : [];
      this.showLicensePicker = false;
    },
    licenseTypeInput(item) {
      if (item.id === this.licenseSelectValueOld.id) {
        this.licenseSelectValue = {};
        this.licenseSelectValueOld = {};
        return;
      }
      this.licenseSelectValueOld = item;
    },
    addWorkTypeConfirm() {
      const find = this.workTypeList.find(item => item.choose);
      if (!find) return this.$toast.fail("请添加作业类别!");
      this.$refs.addWorkTypeForm
        .validate()
        .then(res => {
          this.formBase.workTypeList = [
            ...this.formBase.workTypeList,
            ...this.workTypeList.filter(item => item.choose)
          ];
          this.showWorkTypePicker = false;
          this.workTypeColorTrigger = true;
          this.rescuePlanVisible = this.formBase.workTypeList.some(
            item => item.specialJobType === "2"
          );
          this.getWorkTypeListByConf();
        })
        .catch(e => {
          this.$toast.fail(`请填写必填项`);
        });
    },
    async getWorkTypeListByConf() {
      try {
        const params = {
          workTypeSpecialList: this.workTypeList
            .filter(item => item.choose)
            .map(i => {
              i.licenseType = Array.isArray(i.licenseType)
                ? i.licenseType[0] || ""
                : i.licenseType || "";
              return i;
            })
        };
        const arr = await getWorkTypeList(params);
        this.completeWorkTypeList.push(...arr);
        this.showGuarderPicker.push(false);
        this.showAnalystPicker.push(false);
        this.showPUCPicker.push(false);
      } catch (e) {
        this.$toast.fail(`${projectTextConfig.TEXT_WORK_DEPT}数据请求失败`);
      }
    },
    tempDLOpen() {
      this.$router.push("/workTempSelect");
    },
    setSATableExecutorPerson() {
      if (!this.safetyAnalyTable.some(i => i.choose)) {
        return this.$toast.fail("请选择");
      }
      this.showExecutorPicker = true;
    },
    onExecutorPersonConfirm(ids, list) {
      // TODO: 可能需要添加type
      const p = list.map(item => {
        return {
          ...item,
          name: item.label,
          id: item.value,
          fileId: "",
          personType: "18",
          type: item._mechanismType
        };
      });
      this.safetyAnalyTable = this.safetyAnalyTable.map(item => {
        if (item.choose) {
          item.executorList.push(
            ...p.filter(i => !item.executorList.find(o => o.id === i.id))
          );
        }
        item.choose = false;
        return item;
      });
      this.analyzeTablePersonToJsaRelate();
    },
    async addWorkStep() {
      this.workStepType = "add";
      this.analyzeTableRowForm = {
        step: "", // 工作步骤
        jsaDescribe: "", // 危害描述
        measures: "", // 控制措施
        executorList: [], // 执行人
        choose: false,
        evaluationMethod: this.tableEvaluationMethod,
        showSubItem: false
      };
      this.addWorkStepPopup = true;
      await this.$nextTick();
      this.$refs.addWorkStepForm.resetValidation();
    },
    async addWorkStepConfirm() {
      await this.$refs.addWorkStepForm.validate();
      if (this.workStepType == "add") {
        this.safetyAnalyTable.push(this.analyzeTableRowForm);
      } else {
        this.safetyAnalyTable.splice(
          this.workStepIndex,
          1,
          this.analyzeTableRowForm
        );
      }
      this.addWorkStepPopup = false;
      this.analyzeTablePersonToJsaRelate();
    },
    analyzeTablePersonToJsaRelate() {
      const p = this.safetyAnalyTable.reduce(
        (acc, cur) => {
          const diff = cur.executorList.filter(
            i => !acc.find(f => f.id === i.id)
          );
          acc.push(...diff);
          return acc;
        },
        [...this.formBase.jsaRelateList]
      );
      this.formBase.jsaRelateList = uniqBy(filter(p, "id"), "id");
    },
    workUnitFormPersonToJsaRelate(idx) {
      // const p = this.formBase.workTypeList[idx].workerList.reduce(
      //   (acc, cur) => {
      //     const diff = [
      //       { ...cur.leader },
      //       ...cur.workerList,
      //       ...cur.ordinaryWorkerList,
      //       ...cur.hoistingCommandList,
      //       ...cur.hoistingPersonList,
      //       ...cur.guarderList,
      //       { ...cur.analyst },
      //       { ...cur.productionUnitCommander }
      //     ];
      //     acc.push(...diff);
      //     return acc;
      //   },
      //   [...this.formBase.jsaRelate]
      // );
      const sp = this.formBase.workTypeList[idx].workerList;
      const p = uniqBy(
        filter(
          [
            ...this.formBase.jsaRelateList,
            { ...sp.leader, type: sp.leader.personType },
            ...sp.workerList,
            ...sp.ordinaryWorkerList,
            ...sp.hoistingCommandList,
            ...sp.hoistingPersonList,
            ...sp.guarderList,
            { ...sp.analyst },
            { ...sp.productionUnitCommander }
          ],
          "value"
        ),
        "value"
      ).map(i => {
        i.id = i.value;
        i.name = i.label;
        return i;
      });
      // this.formBase.jsaRelateList = uniqBy(filter(p, "value"), "value");
      this.formBase.jsaRelateList = p;
    },
    personToJsaRelate(data) {
      if (!Array.isArray(data)) {
        data = [data];
      }
      const p = data.reduce(
        (acc, cur) => {
          const find = acc.find(a => a.id === cur.id);
          if (!find) {
            acc.push({ ...cur });
          }
          return acc;
        },
        [...this.formBase.jsaRelateList]
      );
      this.formBase.jsaRelateList = uniqBy(filter(p, "id"), "id");
    },
    colorTagSwitch(data) {
      const obj = {
        0: "putong",
        1: "donghuo",
        2: "shouxiankongjian",
        3: "gaochu",
        4: "mangbanchoudu",
        5: "diaozhuang",
        6: "dongtu",
        7: "duanlu",
        8: "linshiyongdian"
      };
      return obj[data.specialJobType] || "";
    },
    getPersonLabel(persons, key = "label") {
      if (!persons) return "";
      if (Array.isArray(persons)) {
        return persons.map(item => item[key]).join(",");
      }
      return persons[key];
    },
    personPickerWake(ref) {
      if (!this.editable) return;
      this.$refs[ref][0].show();
    },
    workerClick({ specialJobType }, idx) {
      if (this.editable && specialJobType !== "5") {
        this.workerPickerVisible = true;
        this.personConfirmIndex = idx;
      }
    },
    oridnaryWorkerClick({ specialJobType }, idx) {
      if (this.editable && specialJobType !== "5") {
        this.normalPersonPickerVisible = true;
        this.personConfirmIndex = idx;
      }
    },
    licenseTypesSwitch(item, workerType) {
      const find = (item.workTypeLicenseList || []).find(t => {
        return Number(t.workerType) === Number(workerType);
      });
      if ([11, 12].includes(Number(workerType))) {
        return find && find.licenseTypeList
          ? find.licenseTypeList.map(i => i.replace(/,/g, "-")).toString()
          : "";
      }
      return find && find.licenseTypeList
        ? find.licenseTypeList.toString()
        : "";
    },
    showSelectGuarder(idx) {
      if (!this.editable) return;
      this.showGuarderPicker.splice(idx, 1, true);
      this.personConfirmIndex = idx;
    },
    showSelectAnalyst(idx) {
      if (!this.editable) return;
      this.showAnalystPicker.splice(idx, 1, true);
      this.personConfirmIndex = idx;
    },
    showSelectPUC(idx) {
      if (!this.editable) return;
      this.showPUCPicker.splice(idx, 1, true);
      this.personConfirmIndex = idx;
    },
    editExecutorConfirm(ids, list) {
      // TODO: 可能需要添加type
      const p = list.map(item => {
        return {
          ...item,
          name: item.label,
          id: item.value,
          fileId: "",
          type: item._mechanismType,
          personType: "18"
        };
      });
      this.analyzeTableRowForm.executorList = p;
    },
    onPersonConfirm(ids, list, type) {
      const idx = this.personConfirmIndex;
      const alone = ["leader", "analyst", "productionUnitCommander"];
      const concatList = ["hoistingCommandList", "hoistingPersonList"];
      const personTypeMap = {
        guarderList: "2",
        analyst: "5",
        productionUnitCommander: "13"
      };
      const p = list.map(item => {
        return {
          ...item,
          name: item.label,
          id: item.value,
          fileId: "",
          type: item._mechanismType || item.type,
          personType: personTypeMap[type]
        };
      });
      if (alone.includes(type)) {
        this.formBase.workTypeList[idx].workerList[type] = p.length
          ? p[0]
          : {
              id: "",
              name: "",
              fileId: "",
              // 私有
              value: "",
              label: "",
              type: "",
              personType: ""
            };
        return;
      }
      if (concatList.includes(type)) {
        this.$nextTick(() => {
          this.hositingPersonConcat(idx);
        });
      }
      this.formBase.workTypeList[idx].workerList[type] = p;
      this.WPPersonChange(idx);
    },
    onPersonConfirmByCheckSignature(ids, list, type) {
      // const alone = ["jsaLeader"];
      // if (alone.includes(type)) {
      //   this.formBase[type] = list.length
      //     ? {
      //         ...list[0],
      //         id: list[0].value || list[0].id,
      //         name: list[0].label || list[0].name,
      //         value: list[0].value || list[0].id,
      //         label: list[0].label || list[0].name,
      //         fileId: ""
      //       }
      //     : {
      //         id: "",
      //         name: "",
      //         fileId: "",
      //         // 私有
      //         value: "",
      //         label: ""
      //       };
      // } else {
      //   this.formBase[type] = list.map(item => {
      //     return {
      //       ...item,
      //       name: item.label,
      //       id: item.value,
      //       fileId: "",
      //       type: item._mechanismType
      //     };
      //   });
      // }
      const personTypeMap = {
        jsaLeaderList: "17",
        jsaApproverList: "6",
        jsaRelateList: "7"
      };
      this.formBase[type] = list.map(item => {
        return {
          ...item,
          name: item.label,
          id: item.value,
          fileId: "",
          type: item._mechanismType,
          personType: personTypeMap[type]
        };
      });
      if (type !== "jsaRelateList") {
        this.personToJsaRelate(this.formBase[type]);
      }
    },
    hoistingGetPersonLabel(persons) {
      if (!persons) return "";
      if (Array.isArray(persons)) {
        return persons.map(item => item.label).join(",");
      }
      return persons.label;
    },
    WPPersonChange(idx) {
      this.workUnitFormPersonToJsaRelate(idx);
    },
    hositingPersonConcat(idx) {
      const person = this.formBase.workTypeList[idx].workerList;
      const HCommand = person.hoistingCommandList;
      const HPerson = person.hoistingPersonList;
      const allHoistingPerson = HPerson.concat(
        HCommand.filter(
          item => !HPerson.find(itemH => itemH.value === item.value)
        )
      );
      person.workerList = allHoistingPerson;
      this.WPPersonChange(idx);
    },
    pageParamsSwitch() {
      const personKeySwitch = item => {
        item.id = item.value;
        item.name = item.label;
      };
      const params = deepClone(this.formBase);
      delete params.analyst;
      // params.jsaLeader = params.jsaLeader.value;
      // params.jsaApprover = params.jsaApprover.map(i => i.value).join(",");
      // params.jsaRelate = params.jsaRelate.map(i => i.value).join(",");
      const jsa = params.openAnalysis ? "1" : "";
      const work = params.workPlan ? "2" : "";
      const rescue = params.rescuePlan ? "3" : "";
      const construction = params.construction ? "4" : "";
      const drawing = params.drawing ? "5" : "";
      const other = params.other ? "6" : "";
      params.safetyAnalysis = `${jsa}${work}${rescue}${construction}${drawing}${other}`
        .split("")
        .toString();
      // if (params.jsaLeader._mechanismType) {
      //   params.jsaLeaderInfo = {
      //     id: params.jsaLeader.value,
      //     type: params.jsaLeader._mechanismType
      //   };
      // }
      // params.jsaLeader = params.jsaLeader.value;
      // params.jsaApprover = params.jsaApprover.value;
      // params.jsaApproverInfo = params.jsaApprover
      //   .filter(i => i._mechanismType)
      //   .map(i => {
      //     return {
      //       id: i.value,
      //       type: i._mechanismType
      //     };
      //   });
      // params.jsaApprover = params.jsaApprover.map(i => i.value).join(",");
      // params.jsaRelateInfo = params.jsaRelate
      //   .filter(i => i._mechanismType)
      //   .map(i => {
      //     return {
      //       id: i.value,
      //       type: i._mechanismType
      //     };
      //   });
      // params.jsaRelate = params.jsaRelate.map(i => i.value).join(",");
      params.workTypeList = params.workTypeList.map(i => {
        i.licenseType = i.licenseType.toString();
        const wl = i.workerList;
        const workerList = [
          {
            ...wl.leader,
            id: wl.leader.value,
            name: wl.leader.label,
            personType: "1"
          },
          {
            ...wl.analyst,
            id: wl.analyst.value,
            name: wl.analyst.label,
            personType: "5"
          },
          {
            ...wl.productionUnitCommander,
            id: wl.productionUnitCommander.value,
            name: wl.productionUnitCommander.label,
            personType: "13"
          },
          ...wl.workerList.map(i => {
            personKeySwitch(i);
            i.personType = "3";
            return i;
          }),
          ...wl.ordinaryWorkerList.map(i => {
            personKeySwitch(i);
            i.personType = "15";
            return i;
          }),
          ...wl.guarderList.map(i => {
            personKeySwitch(i);
            i.personType = "2";
            return i;
          }),
          ...wl.hoistingCommandList.map(i => {
            personKeySwitch(i);
            i.personType = "11";
            return i;
          }),
          ...wl.hoistingPersonList.map(i => {
            personKeySwitch(i);
            i.personType = "12";
            return i;
          }),
          ...i.workTypePerson
        ];
        i.workerList = workerList.filter(i => i.id);
        return i;
      });
      params.jsaRelateList.forEach(i => {
        i.personType = "7";
      });
      params.jsaStepList = deepClone(this.safetyAnalyTable);
      params.protectiveEquipment = params.protectiveEquipment.toString();
      if (this.rescuePlanVisible) {
        const {
          plan,
          planIds,
          measureInner,
          measureOuter,
          measureOther,
          device,
          deviceIds,
          territorialLeaderList
        } = this.rescuePlanForm;
        const dictSelectList = [];
        plan.forEach(item => {
          dictSelectList.push({
            ...item,
            dictId: item.dictId < 0 ? "" : item.dictId, // 新增项 dictId 传空
            dictStatus: planIds.includes(item.dictId) ? 1 : 0
          });
        });
        measureInner.forEach(item => {
          dictSelectList.push({
            ...item,
            dictStatus: item.checked ? 1 : 0
          });
        });
        measureOuter.forEach(item => {
          dictSelectList.push({
            ...item,
            dictStatus: item.checked ? 1 : 0
          });
        });
        dictSelectList.push(...measureOther);
        device.forEach(item => {
          dictSelectList.push({
            ...item,
            dictId: item.dictId < 0 ? "" : item.dictId, // 新增项 dictId 传空
            dictStatus: deviceIds.includes(item.dictId) ? 1 : 0
          });
        });
        params.dictSelectList = dictSelectList.map(item => {
          // 新建
          if (!this.rescuePlanOrigin.length) {
            item.dictId = item.id;
            item.id = "";
          }
          return item;
        });
        params.territorialLeaderList = territorialLeaderList.map(item => ({
          ...item,
          fileId: item.fileId || ""
        }));
      }
      return params;
    },
    sleep(n) {
      return new Promise(resolve => {
        setTimeout(resolve, n);
      });
    },
    async pageSave() {
      // if (!this.safetyAnalyTable.length) {
      //   return this.$toast.fail("请填写作业前安全分析表");
      // }
      // const every = this.safetyAnalyTable.every(i => i.executorList.length);
      // if (!every) return this.$toast.fail("请选择执行人");
      this.dynamicRule = false;
      await this.sleep(100);
      await this.$refs.form.validate();
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认保存？"
        })
        .then(async () => {
          const params = this.pageParamsSwitch();
          if (this.type === "add") {
            await addWorkJsa(params);
          } else {
            await updateWorkJsa(params);
          }
          this.$toast.success("保存成功");
          this.$router.back();
        });
    },
    async pageSubmit() {
      if (this.isRH) {
        const hasJob = this.formBase.workTypeList.some(
          item => item.specialJobType === "2"
        );
        const hasSign = this.rescuePlanForm.territorialLeaderList.some(
          item => item.fileId
        );
        if (hasJob && !hasSign) {
          return this.$toast.fail("请点击保存后，进行属地负责人签名");
        }
      }
      if (this.formBase.openAnalysis) {
        if (!this.safetyAnalyTable.length) {
          return this.$toast.fail("请填写作业前安全分析表");
        }
        const every = this.safetyAnalyTable.every(i => i.executorList.length);
        if (!every) {
          return this.$toast.fail("请选择执行人");
        }
      }
      await this.$refs.form.validate();
      this.$dialog
        .confirm({
          title: "提示",
          message:
            "请确认作业人是否需要资质及所需资质类型，一旦提交，后续只能选择有资质的作业人"
        })
        .then(async () => {
          const params = this.pageParamsSwitch();
          if (this.type === "add") {
            await commitWorkJsa(params);
          } else {
            await updateCommitWorkJsa(params);
          }
          this.$toast.success("提交成功");
          this.$router.back();
        });
    },
    pageSignatureSubmit() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "提交后进入待反馈，不可补充签名。确认提交？"
        })
        .then(async () => {
          const params = {
            id: this.id,
            status: 0
          };
          await signedWorkJsa(params);
          this.$toast.success("提交成功");
          this.$router.back();
        });
    },
    reject() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认退回？"
        })
        .then(async () => {
          // const params = {
          //   id: this.id,
          //   status: 1
          // };
          // await signedWorkJsa(params);
          this.rejectJsa = true;
          this.rejectForm.rejectReason = "";
          // this.$toast.success("退回成功");
          // this.$router.back();
        });
    },
    // 确认退回
    async rejectFormCofm() {
      const params = {
        id: this.id,
        status: 1,
        returnReason: this.rejectForm.rejectReason
      };
      await this.$refs.rejectForm.validate();
      await signedWorkJsa(params).then(res => {
        this.$toast.success("退回成功");
        this.$router.back();
      });
    },
    async sameAddWorkticket() {
      const jsaName = this.formBase.jsaName;
      this.SAWForm = {
        workTypeList: this.formBase.workTypeList.map(i => {
          return {
            ...i,
            jsaName: jsaName,
            choose: false,
            planStart: "", // 计划开始时间
            planEnd: "", // 计划结束时间
            proposerList: [
              {
                value: this.userInfo.id,
                label: this.userInfo.nickName,
                id: this.userInfo.id,
                name: this.userInfo.nickName,
                type: "0"
              }
            ],
            proposerName: this.userInfo.nickName
          };
        }),
        approverList: [],
        approverName: [],
        supervisorList: [],
        supervisorName: ""
      };
      this.SAWVisibi = true;
      await this.$nextTick();
      this.$refs.sameAddWorkticketForm.resetValidation();
    },
    feedBack() {
      this.InputDialogFun(
        "总结与反馈",
        () =>
          new Promise(rlv => {
            rlv();
          }),
        [],
        (res, arg) => {
          feedBackWorkJsa({
            id: this.id,
            feedBack: arg[0]
          })
            .then(res => {
              if (res) {
                this.completeWorkTypeList = [];
                this.getWorkJsaInfo();
                this.$toast("保存成功");
                return;
              }
              this.$toast("保存失败, 请重试");
            })
            .catch(e => {
              this.$toast("保存失败, 请重试");
            });
        },
        false,
        null,
        null
      );
    },
    InputDialogFun(
      title,
      api,
      apiParamsList = [],
      cb,
      hasCheckPerson,
      ticketId,
      department,
      hasInput = true,
      isRequired = true,
      maxlength = 500
    ) {
      const successCb = function() {
        api(...apiParamsList, ...arguments)
          .then(res => {
            InputDialog.close();
            this.$toast.success("操作成功");
            cb(res, arguments);
          })
          .catch(error => {
            InputDialog.removeLoading();
            this.$toast.fail(error.msg || "请求失败,请重试");
          });
      };
      InputDialog.show({
        title,
        successCb,
        hasCheckPerson,
        ticketId,
        department,
        hasInput,
        isRequired,
        maxlength
      });
    },
    clickProcess() {
      this.$router.push("/workBeforeProcess/" + this.$route.params.id);
    },
    exportClick() {
      if (!window?.android?.downLoadFile) {
        this.$toast.fail("此版本不支持导出，请更新后使用");
        return;
      }
      const fileName = `作业活动管理-${this.formBase.jsaCode}.docx`;
      const psmPath = window.globalConfig.VUE_APP_API_PSM_PATH;
      const url = `${psmPath}/export/work/jsa/${this.id}`;
      window.android.downLoadFile(url, fileName);
    },
    signatureClick(person, force = false) {
      if (person.fileId || !this.checkoutUser) {
        // 已上传过，直接预览
        esign.showImage(fileUrl + person.fileId);
        return;
      }
      if (!force && this.formBase.status !== 1) return;
      esign.show(file => {
        let params = {
          workJsaId: this.id,
          personId: person.id,
          fileId: file.id
        };
        signatureWorkJsa(params)
          .then(re => {
            this.$toast.success("签名成功");
            person.fileId = file.id;
            this.getWorkJsaInfo();
          })
          .catch(error => {
            this.$toast.fail("签名失败，请重试");
          });
      });
    },
    navBarBack() {
      if (
        (this.type === "add" || this.formBase.status === 0) &&
        this.formBase.cuserId === this.userInfo.id
      ) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "确认放弃录入的内容？"
          })
          .then(() => {
            this.$router.back();
          });
        return;
      }
      this.$router.back();
    },
    getColumnValue(row, prop, hasPlaceholder = true) {
      const placeholder = hasPlaceholder ? "--" : "";
      if (prop === "stepTypeCode") {
        return (
          this.classificationData.find(item => item.value === row[prop])
            ?.label || "--"
        );
      }
      if (prop === "riskLevelCode" || prop === "inherentRiskLevelCode") {
        if (/[\u4e00-\u9fa5]/.test(row[prop])) {
          return row[prop];
        }
        return (
          this.RISK_LEVEL.find(item => item.value === row[prop])?.label ||
          placeholder
        );
      }
      if (prop === "governanceLevel" || prop === "inherentGovernanceLevel") {
        return (
          this.levelData.find(item => item.value === row[prop])?.label ||
          placeholder
        );
      }
      if (prop === "executorList") {
        return row[prop].map(item => item.name).toString();
      }
      if (prop === "evaluationMethod") {
        return (
          this.evaluationMethodData.find(
            item => item.value === String(row[prop])
          )?.label || "--"
        );
      }
      if (["", null, undefined].includes(row[prop])) {
        return "--";
      }
      if (prop === "acceptStatus") {
        return String(row[prop]) === "1" ? "是" : "否";
      }
      return row[prop];
    },
    async getSelectStepsClassification() {
      this.classificationData = await getSelectStepsClassification();
    },
    async getJsaAnalysesGovernanceLevel() {
      this.levelData = await getJsaAnalysesGovernanceLevel();
    },
    toggleSubItem(row, index) {
      row.showSubItem = !row.showSubItem;
      this.$set(this.safetyAnalyTable, index, row);
    },
    async getWorkDict(pageId) {
      const data = {
        plan: [],
        planIds: [],
        measureOuter: [],
        measureInner: [],
        measureOther: [],
        device: [],
        deviceIds: []
      };
      const groupType = 10; // 救援计划
      let res = [];
      if (pageId) {
        res = await getWorkDictOptions({ jsaId: pageId });
        if (!res?.length) {
          res = await getWorkDictCommons({ groupType });
          res = res.map(item => ({
            ...item,
            dictId: item.id,
            id: null
          }));
        }
      } else {
        res = await getWorkDictCommons({ groupType });
        res = res.map(item => ({
          ...item,
          dictId: item.id,
          id: null
        }));
      }
      this.rescuePlanOrigin = res;
      res.forEach(item => {
        item.dictId = item.dictId || item.id; // 新增字典项无 dictId
        switch (item.dictType) {
          case 1004:
            data.plan.push(item);
            item.dictStatus && data.planIds.push(item.dictId);
            break;
          case 1002:
            data.measureOuter.push({
              ...item,
              checked: item.dictStatus ? true : false
            });
            break;
          case 1001:
            data.measureInner.push({
              ...item,
              checked: item.dictStatus ? true : false
            });
            break;
          case 1005:
            data.measureOther.push(item);
            break;
          case 1003:
            data.device.push(item);
            item.dictStatus && data.deviceIds.push(item.dictId);
            break;
        }
      });
      this.rescuePlanForm = {
        ...this.rescuePlanForm,
        ...data
      };
    },
    territorialLeaderChange(val, list) {
      this.rescuePlanForm.territorialLeaderList = list.map(p => {
        return {
          id: p.id,
          name: p.name,
          value: p.id,
          label: p.name,
          fileId: "",
          type: p._mechanismType || p.type,
          personType: "3401"
        };
      });
    },
    addMeasureOther() {
      this.InputDialogFun(
        "添加补充措施",
        () =>
          new Promise(rlv => {
            rlv();
          }),
        [],
        (res, arg) => {
          this.rescuePlanForm.measureOther.push({
            id: "",
            name: arg[0],
            dictId: "",
            dictType: 1005,
            groupType: 10,
            dictStatus: 1
          });
        },
        false,
        null,
        null,
        true,
        true,
        500
      );
    },
    delMeasureOther(index) {
      this.rescuePlanForm.measureOther.splice(index, 1);
    },
    rescuePlanStateChange() {
      const hasJob = this.formBase.workTypeList.some(
        item => item.specialJobType === "2"
      );
      this.rescuePlanVisible = hasJob;
      if (!hasJob) {
        this.rescuePlanForm = {
          ...this.rescuePlanForm,
          planIds: [],
          measureInner: this.rescuePlanForm.measureInner.map(item => ({
            ...item,
            checked: false
          })),
          measureOuter: this.rescuePlanForm.measureOuter.map(item => ({
            ...item,
            checked: false
          })),
          measureOther: [],
          deviceIds: [],
          territorialLeaderList: []
        };
      }
    },
    getDictId() {
      this.rescuePlanForm.dictIdCounter -= 1;
      return this.rescuePlanForm.dictIdCounter;
    },
    planAdd() {
      this.InputDialogFun(
        "添加救援计划",
        () =>
          new Promise(rlv => {
            rlv();
          }),
        [],
        (res, arg) => {
          this.planSave(arg[0]);
        },
        false,
        null,
        null,
        true,
        true,
        32
      );
    },
    planSave(name) {
      const dictId = this.getDictId();
      this.rescuePlanForm.plan.push({
        id: "",
        name,
        dictId,
        dictType: 1004,
        groupType: 10,
        dictStatus: 1,
        delete: true
      });
      this.rescuePlanForm.planIds.push(dictId);
    },
    planDelete(dictId) {
      this.rescuePlanForm.plan = this.rescuePlanForm.plan.filter(
        item => item.dictId !== dictId
      );
      this.rescuePlanForm.planIds = this.rescuePlanForm.planIds.filter(
        item => item !== dictId
      );
    },
    deviceAdd() {
      this.InputDialogFun(
        "添加救援设备",
        () =>
          new Promise(rlv => {
            rlv();
          }),
        [],
        (res, arg) => {
          this.deviceSave(arg[0]);
        },
        false,
        null,
        null,
        true,
        true,
        32
      );
    },
    deviceSave(name) {
      const dictId = this.getDictId();
      this.rescuePlanForm.device.push({
        id: "",
        name,
        dictId,
        dictType: 1003,
        groupType: 10,
        dictStatus: 1,
        delete: true
      });
      this.rescuePlanForm.deviceIds.push(dictId);
    },
    deviceDelete(dictId) {
      this.rescuePlanForm.device = this.rescuePlanForm.device.filter(
        item => item.dictId !== dictId
      );
      this.rescuePlanForm.deviceIds = this.rescuePlanForm.deviceIds.filter(
        item => item !== dictId
      );
    },
    filterTree(val) {
      if (!val) return this.initFilterTree();
      const labelKey = "label";
      const valueKey = "id";
      const treeData = this.deptTreeData;

      function setOwn(tree, name, parent = treeData) {
        for (let i = 0; i < tree.length; i++) {
          const currentNode = tree[i];
          if (currentNode[labelKey].includes(val)) {
            currentNode.filtered = true;
            const parentNode = setParent(parent, currentNode[valueKey]);
            if (parentNode && !parentNode._root) {
              treeData.forEach(tree => setParent(tree, parentNode[valueKey]));
            }
          }
          if (currentNode.children) {
            setOwn(currentNode.children, name, currentNode);
          }
        }
      }

      function setParent(parent, childId) {
        if (!parent) return;
        for (let i = 0; i < (parent?.children?.length || 0); i++) {
          const node = parent.children[i];
          if (node[valueKey] === childId) {
            parent.filtered = true;
            return parent;
          }
          if (node.children && node.children > 0) {
            return setParent(node, name);
          }
        }
      }
      setOwn(treeData, val);
    },
    initFilterTree() {
      // 初始化需要响应式
      this.flatTreeData.forEach((item, idx) => {
        this.$set(item, "filtered", true);
        this.$set(this.flatTreeData, idx, item);
      });
    },
    resetFilterTree() {
      this.flatTreeData.forEach(item => (item.filtered = false));
    },
    onFilter() {
      this.resetFilterTree();
      this.filterTree(this.filterString);
    },
    flatTree() {
      function flatTree(tree, treeMap = [], depth = 0) {
        if (!(tree && tree.length)) return;
        depth++;
        return tree.reduce((acc, cur) => {
          cur.depth = depth;
          acc.push(cur);
          if (cur.children && cur.children.length) {
            flatTree(cur.children, treeMap, depth);
          }
          return acc;
        }, treeMap);
      }

      return flatTree(this.deptTreeData);
    },
    handleSearch() {
      if (!this.keyword) {
        this.contractorFilterItems = this.contractorsSelectData;
        return;
      }
      this.contractorFilterItems = this.contractorsSelectData.filter(item =>
        item.label.includes(this.keyword)
      );
    },
    handleClear() {
      this.keyword = "";
      this.handleSearch();
    },
    workTypeIdDisabled({ workTypeId }) {
      return (
        this.isDangerfacilities && this.disabledWorkTypes.includes(workTypeId)
      );
    },
    licenseTypeFormatter(val) {
      return val ? val.replace(/,/g, "-") : "";
    },
    async jsaApprovePass() {
      await jsaApprovePass(this.id);
      this.$toast.success("审批成功");
      this.$router.back();
    },
    async jsaApproveRollback() {
      await jsaApproveRollback(this.id);
      this.$toast.success("退回成功");
      this.$router.back();
    },
    async jsaApproveRecall() {
      await jsaApproveRecall(this.id);
      this.$toast.success("撤回成功");
      this.$router.back();
    },
    async getWorkListPersons(contractorStatus, deptCode) {
      const params = {};
      if (Number(contractorStatus) === 1) {
        params.contractorId = deptCode;
      } else {
        params.department = deptCode;
      }
      let options = await getWorkListPersons(params);
      options = (options || []).map(item => {
        item.id = item.personId;
        item.personType = "28";
        return item;
      });
      this.$set(this.workTypePersonOptions, this.curWorkUnitIdx, options);
    },
    workTypePersonClick({ deptCode }, idx) {
      this.curWorkUnitIdx = idx;
      if (!deptCode) {
        return this.$toast.fail(`请选择${projectTextConfig.TEXT_WORK_DEPT}`);
      }
      this.workTypePersonCur = this.workTypeList[
        this.curWorkUnitIdx
      ].workTypePerson.map(item => item.personId);
      this.workTypePersonVisible = true;
    },
    workTypePersonChange(val) {
      this.workTypeList[this.curWorkUnitIdx].workTypePerson = val;
    },
    async templateInit() {
      let temp = window.sessionStorage.getItem("temp");
      if (!temp) return;
      temp = temp ? JSON.parse(temp) : null;
      window.sessionStorage.removeItem("temp");
      this.completeWorkTypeList = [];
      if (temp._dataTag === "jsa") {
        const info = await getJsaInfo(temp.id);
        const jsaStepList = (info.assessmentJsaAnalyseVOS || []).map(i => {
          return {
            ...i,
            step: i.stepContent, // 工作步骤
            jsaDescribe: i.hazardFactor, // 危害描述
            measures: i.managementMeasures, // 控制措施
            executorList: [],
            choose: false,
            showSubItem: false
          };
        });
        this.formBase = {
          ...this.formBase,
          riskAreaId: info.riskAreaId,
          riskAreaName: info.riskAreaName,
          jsaName: info.jsaName,
          facilityId: info.poiId,
          facilityType: info.poiType,
          facilityName: info.poiName,
          openAnalysis: 1,
          workTypeList: [],
          jsaStepList,
          department: info.deptCode,
          departmentName: info.deptName,
          departmentNames: info.deptName
        };
        this.getGeo(info.poiId);
        this.getGeoInfo(info.riskAreaId);
        this.safetyAnalyTable = deepClone(jsaStepList);
        this.workAreaChange(info.poiId, info.poiName, [
          {
            type: info.poiType
          }
        ]);
        return;
      }
      const info = await getWorkJsaInfo(temp.id);
      const personKeySwitch = {
        id: "",
        name: "",
        value: "",
        label: "",
        fileId: "",
        type: "",
        personType: ""
      };
      info.workTypeList = info.workTypeList.map(w => {
        w.licenseType = w.licenseType ? [w.licenseType] : [];
        w.workerList = {
          leader: { ...personKeySwitch, personType: "1" },
          analyst: { ...personKeySwitch, personType: "5" },
          productionUnitCommander: { ...personKeySwitch, personType: "13" },
          workerList: [],
          ordinaryWorkerList: [],
          guarderList: [],
          hoistingCommandList: [],
          hoistingPersonList: []
        };
        return w;
      });
      const jsaStepList = (info.jsaStepList || []).map(i => {
        i.executorList = [];
        return i;
      });
      info.safetyAnalysis = info.safetyAnalysis || "";
      this.getGeo(info.facilityId);
      this.getGeoInfo(info.riskAreaId);
      this.formBase = {
        ...this.formBase,
        ...info,
        riskAreaId: info.riskAreaId,
        riskAreaName: info.riskAreaName,
        jsaName: info.jsaName,
        facilityId: info.facilityId,
        facilityType: info.facilityType,
        facilityName: info.facilityName,
        department: info.department,
        departmentName: info.departmentName,
        departmentNames: info.departmentName,
        openAnalysis: info.safetyAnalysis.includes("1") ? 1 : 0,
        workPlan: info.safetyAnalysis.includes("2") ? 1 : 0,
        planFile: info.planFile || [],
        rescuePlan: info.safetyAnalysis.includes("3") ? 1 : 0,
        rescueFile: info.rescueFile || [],
        construction: info.safetyAnalysis.includes("4") ? 1 : 0,
        constructionFile: info.constructionFile || [],
        drawing: info.safetyAnalysis.includes("5") ? 1 : 0,
        drawingFile: info.drawingFile || [],
        other: info.safetyAnalysis.includes("6") ? 1 : 0,
        otherFile: info.otherFile || [],
        workTypeList: info.workTypeList,
        jsaStepList: jsaStepList,
        protectiveEquipment: info.protectiveEquipment
          ? info.protectiveEquipment.split(",")
          : []
      };
      this.safetyAnalyTable = jsaStepList.map(i => {
        i.executorList = i.executorList.map(e => {
          e.choose = false;
          return e;
        });
        return i;
      });
      try {
        const params = {
          workTypeSpecialList: info.workTypeList.map(i => {
            i.licenseType = Array.isArray(i.licenseType)
              ? i.licenseType[0] || ""
              : i.licenseType || "";
            return i;
          })
        };
        const arr = await getWorkTypeList(params);
        arr.forEach(i => {
          i.licenseType = i.licenseType || [];
        });
        const len = arr.length;
        this.showGuarderPicker = Array(len).fill(false);
        this.showAnalystPicker = Array(len).fill(false);
        this.showPUCPicker = Array(len).fill(false);
        this.completeWorkTypeList.push(...arr);
      } catch (e) {
        this.$toast.fail(`${projectTextConfig.TEXT_WORK_DEPT}数据请求失败`);
      }
    }
  }
};
</script>

<style lang="scss">
.form-box,
.add-work-type-form-box {
  .van-field__label {
    width: auto;
    // white-space: nowrap;
  }
}
.work-unit-popup {
  .content {
    .van-tabs__nav {
      margin: 0 50px 0 40px;
    }
  }
}
</style>

<style lang="scss" scoped>
.form-box {
  padding: 58px 0 0;
  margin: 0 0 16px;
  height: calc(100vh - 58px);
  overflow: auto;
  .group-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 16px 0;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #2e2e4d;
    }
    .title-2 {
      margin: 0 0 0 6px;
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
    }
    .choose-temp-btn {
      // width: 80px;
      height: 32px;
      padding: 6px 9px;
    }
  }
  .analysis-separate {
    height: 38px;
    line-height: 38px;
    padding: 0 18px 0;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    background-color: $--color-primary;
    .iconJSA1 {
      margin: 0 8px 0 0;
    }
  }
  .separate {
    width: 100%;
    height: 8px;
    background-color: #eceef2;
  }
  .sa-box {
    .title-row {
      display: flex;
      //justify-content: space-between;
      padding: 7px 14px 7px 16px;
      background-color: #eceef2;
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 17px;
      span:nth-of-type(1) {
        flex: 1;
      }
      span:nth-of-type(2) {
        flex: 0 0 14vw;
      }
      span:nth-of-type(3) {
        flex: 0 0 7vw;
      }
    }
    .list-box {
      position: relative;
      padding: 0 16px 20px;
      .item-wrap:not(:last-child) {
        border-bottom: 1px solid #e1e3e8;
      }
      .item-box {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #2e2e4d;
        line-height: 17px;
        padding: 12px 0 0;
        .left {
          width: 27px;
          align-self: center;
        }
        .middle {
          flex: 1;
          // padding: 0 32px 0 0;
          padding: 0 15px 0 0;
          .sub-item-btn {
            position: absolute;
            left: 50%;
            bottom: 4px;
            transform: translateX(-50%);
            color: #1676ff;
          }
          .title {
            font-weight: 500;
            margin: 0 0 8px;
            .txt-red {
              color: #f13030;
            }
          }
          .harm-box {
            display: flex;
            margin: 0 0 5px;
            .text {
              flex: 1;
            }
          }
          .label {
            width: 60px;
          }
        }
        .right {
          //width: 54px;
          flex: 0 0 13vw;
          align-self: center;
        }
        .operation {
          flex: 0 0 8vw;
          text-align: center;
          color: #888;
          padding-top: 10px;
          div:nth-of-type(1) {
            margin-bottom: 5px;
          }
        }
        .right.signature {
          width: 82px;
        }
        .sub-list {
          // flex-basis: 100%;
        }
      }
      .sub-item-box {
        font-size: 12px;
        padding: 10px;
        line-height: 1.5;
        border-radius: 6px;
        background: #f5f7fa;
        color: #8c8f97;
      }
    }
    &.rescue-plan-wrap {
      .title-level-3 {
        padding: 0 16px;
        font-size: 14px;
        color: #15171b;
        margin-top: 16px;
      }
      .list-box {
        .item-box {
          padding-bottom: 0;
          .middle {
            padding-right: 0;
          }
        }
      }
      .title-level-2 {
        padding: 16px 16px 6px;
        color: #15171b;
      }
      .iconfont-cancle {
        color: #f53f3f;
      }
    }
  }
  .sa-btn-box {
    // padding: 16px;
    // line-height: 17px;
    font-size: 12px;
    i,
    .iconfont {
      vertical-align: middle;
      margin: 0 6px 0 0;
    }
    span {
      vertical-align: middle;
    }
  }
  .wt-container {
    .wt-title {
      height: 22px;
      line-height: 22px;
      padding: 0 12px 0 0;
      font-size: 14px;
      font-weight: 500;
      color: #2e2e4d;
      .color-tag {
        display: inline-block;
        vertical-align: middle;
        width: 12px;
        height: 14px;
        margin: 0 4px 0 0;
      }
      .color-tag.donghuo {
        background-color: #2da6e9;
      }
      .color-tag.gaochu {
        background-color: #51c8ed;
      }
      .color-tag.linshiyongdian {
        background-color: #0fb8c5;
      }
      .color-tag.shouxiankongjian {
        background-color: #59cbc0;
      }
      .color-tag.mangbanchoudu {
        background-color: #fac967;
      }
      .color-tag.diaozhuang {
        background-color: #f69b64;
      }
      .color-tag.dongtu {
        background-color: #f176a6;
      }
      .color-tag.duanlu {
        background-color: #bb61a6;
      }
      .color-tag.putong {
        background-color: #3679bf;
      }
      .text {
        display: inline-block;
        vertical-align: middle;
        .type {
          font-weight: 500;
        }
        .company {
          font-size: 12px;
          color: #8c8f97;
          font-weight: 400;
          margin: 0 0 0 6px;
        }
      }
    }
  }
  .form {
    padding: 0 0 60px;
  }
  .van-field__label {
    width: auto;
  }
  .work-type-list-box {
    padding: 0 16px;
    .item-box {
      display: flex;
      justify-content: space-between;
      padding: 10px 8px 10px 12px;
      margin: 0 0 8px;
      background-color: #f5f7fa;
      border-radius: 6px;
      .left-box {
        flex: 1;
        word-break: break-all;
        font-size: 14px;
        color: #8c8f97;
        line-height: 20px;
        padding: 0 2px 0 0;
        .type-name {
          color: #8c8f97;
          margin: 0 0 6px;
        }
        p:nth-child(2) {
          margin: 0 0 2px;
        }
      }
      .close-box {
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: #aeb3c0;
        color: #efefef;
        font-size: 14px;
        text-align: center;
        line-height: 13px;
        border-radius: 13px;
      }
    }
  }
  .work-link-box {
    padding: 4px 16px 0;
    .item-box {
      display: flex;
      margin: 0 0 8px;
      div {
        flex: 1;
      }
      .left-box {
        flex: initial;
        padding: 2px 4px 0 0;
      }
      .content {
        flex: initial;
        width: 295px;
        font-size: 14px;
        font-weight: 400;
        color: #1676ff;
        line-height: 20px;
      }
    }
    .close-box {
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: #aeb3c0;
      color: #efefef;
      font-size: 14px;
      text-align: center;
      line-height: 13px;
      border-radius: 13px;
      margin: 2px 0 0 14px;
    }
  }
  .feedback-box {
    padding: 0 16px 0;
  }
  .feedback-content {
    font-size: 13px;
    font-weight: 400;
    color: #8c8f97;
    line-height: 22px;
  }
  .feedback-oper {
    margin: 8px 0 0;
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    color: #8c8f97;
    line-height: 17px;
  }
  .footer-box {
    display: flex;
    position: absolute;
    bottom: 0;
    width: calc(100% - 32px);
    padding: 0 16px 16px;
    z-index: 1;
    // background-color: #fff;
    .confirm-btn {
      width: 165px;
      height: 44px;
      font-size: 18px;
      font-weight: 500;
      padding: 0; // 防止4个按钮同时显示时，“批量建票”文字换行
    }
    .confirm-btn.san {
      width: 113px;
    }
    .confirm-btn.san:not(:last-child) {
      margin: 0 2px 0 0;
    }
    .confirm-btn:not(:last-child) {
      margin: 0 13px 0 0;
    }
    .confirm-btn.full,
    .confirm-btn:first-child:last-child {
      width: 100%;
      margin: 0;
    }
    .btn {
      flex: 1;
    }
    .btn + .btn {
      margin-left: 10px;
    }
  }
  .custom-input-person {
    .person-box {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2e2e4d;
      margin: 0 0 0 12px;
    }
  }
  .pp-tag-content {
    // position: relative;
    .pp-option-close {
      margin: 0px 0 0 4px;
      vertical-align: text-top;
      // position: absolute;
      // top: 4px;
      // right: 4px;
    }
  }
}
.work-type-popup {
  height: 100vh;
  overflow: hidden;
  .header {
    // position: relative;
    display: flex;
    // justify-content: space-between;
    padding: 14px 16px 13px;
    .close-icon {
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
      flex: 1;
      text-align: center;
      margin: 0 0 0 -16px;
      pointer-events: none;
    }
  }
  .content {
    overflow: auto;
    height: calc(100% - 110px);
  }
  .confirm-btn-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px 16px;
    z-index: 1;
    background-color: #fff;
    .confirm-btn {
      width: 343px;
      height: 44px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.saw-popup {
  .confirm-btn-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px 16px;
    z-index: 1;
    background-color: #fff;
    .cancel-btn,
    .confirm-btn {
      width: 165px;
      height: 44px;
      font-size: 18px;
      font-weight: 500;
    }
    .cancel-btn {
      color: #8c8f97;
      margin: 0 13px 0 0;
      background-color: #ffffff;
      border: 1px solid #c7c9d0;
    }
  }
}
.add-work-type-form-box {
  .checkbox-box {
    display: inline-flex;
    vertical-align: middle;
  }
  .checkbox-name {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 22px;
    vertical-align: middle;
    margin: 0 6px 0;
    &.disabled {
      color: #c8c9cc;
    }
  }
  .collapse-item-box {
    border-bottom: 8px solid #eceef2;
  }
  .wbsad-blue {
    color: #1890ff;
  }
  .wbsad-red {
    color: #f5222d;
  }
}
.work-unit-popup {
  height: 75vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .content {
    overflow: hidden;
    position: relative;
    height: 100%;
    .title {
      line-height: 44px;
      text-align: center;
    }
    .close-icon {
      position: absolute;
      top: 16px;
      left: 19px;
      z-index: 1;
      color: #9496a3;
      font-size: 12px;
    }
    .confirm-btn-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 16px 16px;
      z-index: 1;
      background-color: #fff;
      .confirm-btn {
        width: 343px;
        height: 44px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .contractors-list {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(75vh - 170px);
      .contractors-list-item {
        padding: 14px 16px;
        &.active {
          color: $--color-primary;
        }
        &.active-bgc {
          background-color: rgba(22, 118, 255, 0.1);
        }
      }
    }
    .org-tree-container {
      padding: 0 16px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(75vh - 170px);
    }
  }
}
.add-work-step-popup {
  height: 100vh;
  overflow: hidden;
  .van-cell:last-child::after {
    display: initial;
  }
  .header {
    display: flex;
    padding: 14px 16px 13px;
    .close-icon {
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
      flex: 1;
      text-align: center;
      margin: 0 0 0 -16px;
      pointer-events: none;
    }
  }
  .content {
    overflow: auto;
    height: calc(100% - 110px);
    .label {
      padding: 14px 16px 0;
      font-size: 14px;
      font-weight: 400;
      color: #2e2e4d;
    }
    .grey-area {
      padding-left: 15px;
    }
  }
  .confirm-btn-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px 16px;
    z-index: 1;
    background-color: #fff;
    .cancel-btn,
    .confirm-btn {
      width: 165px;
      height: 44px;
      font-size: 18px;
      font-weight: 500;
    }
    .cancel-btn {
      color: #8c8f97;
      margin: 0 13px 0 0;
      background-color: #ffffff;
      border: 1px solid #c7c9d0;
    }
  }
}
.icon-process {
  font-size: 24px;
}
.btn-select-person {
  display: inline-block;
  width: 86px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background-color: #1676ff;
  color: #ffffff;
  padding: 0;
  margin: 0 0 0 8px;
}
.tag-box {
  &.inline {
    display: inline-block;
  }
  .tag {
    margin: 0 0 8px 8px;
  }
}
.title-bar-1 {
  position: relative;
  padding-top: 6px;
  .title {
    position: absolute;
    left: 6px;
    top: 0;
    width: 222px;
    height: 32px;
    line-height: 32px;
    background: url("~@/assets/images/titleBg.png") no-repeat;
    background-size: contain;
    font-size: 16px;
    color: #fff;
    i {
      margin: 0 6px 0 20px;
    }
  }
  .content {
    height: 26px;
    line-height: 26px;
    background: #f3f5fa;
    // padding-left: 308px;
    color: #99a3af;
  }
}
.tips-icon {
  vertical-align: middle;
  color: #c8c9cc;
}
.tips-popup {
  width: 315px;
  border-radius: 4px;
  background-color: #fff;
  min-height: 124px;
  padding: 18px 37px;
  box-sizing: border-box;
  &-title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  &-text {
    margin-top: 16px;
    font-size: 14px;
    color: #2e2e4d;
    line-height: 1.5;
  }
}
</style>
