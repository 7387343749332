<template>
  <van-field
    v-if="isBy"
    :is-link="false"
    :clickable="false"
    :readonly="true"
    :required="false"
    :value="innerValue"
    name="MaintenanceHours"
    label="检维修工时（h）"
    :placeholder="editable ? '自动计算' : '--'"
    input-align="right"
    error-message-align="right"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "MaintenanceHours",
  props: {
    value: {
      type: [Number, String]
    },
    startTime: {
      type: String,
      default: ""
    },
    endTime: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: ""
    };
  },
  created() {
    if (this.startTime && this.endTime) {
      this.innerValue = this.getHours();
    }
  },
  methods: {
    getHours() {
      const s = this.startTime;
      const e = this.endTime;
      let diff = 0;
      if (s && e) {
        diff = dayjs(e).diff(s, "hour");
      }
      return diff;
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("update:value", newVal);
    },
    startTime() {
      this.innerValue = this.getHours();
    },
    endTime() {
      this.innerValue = this.getHours();
    }
  }
};
</script>

<style lang="scss" scoped></style>
